import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './page/dashboard/dashboard.component';
import { LoginComponent } from './page/login/login.component';
import {TrendsComponent} from './page/trends/trends.component';
import {AdminScriptComplianceComponent} from './page/admin-script-compliance/admin-script-compliance.component';
import { CloudServerComponent } from './page/cloud-server/cloud-server.component';

const routes: Routes = [  {path: 'dashboard', component: DashboardComponent},
    {path: 'login', component: LoginComponent},
    {path: 'trends', component: TrendsComponent},
    {path: 'cloudServers', component: CloudServerComponent},
    {path: 'adminScriptCompliance', component: AdminScriptComplianceComponent},
    {path: '', component: DashboardComponent}];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
