import { Component, OnInit ,Inject} from '@angular/core';
import { MatDialog,MatDialogConfig,  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {HttpClient} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
@Component({
    selector: 'app-copylink-box',
    templateUrl: './copylink-box.component.html',
    styleUrls: ['./copylink-box.component.css']
})
export class CopylinkBoxComponent implements OnInit {
    transferURL='';
    saveName='';

    constructor(public httpClient:HttpClient,
        public snackbar: MatSnackBar,
        public dialogRef: MatDialogRef<CopylinkBoxComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit(): void {
        this.transferURL = window.location.protocol+'//'+window.location.host+'/?transferGUID='+this.data.transferGuid;
        const matDialogConfig: MatDialogConfig = new MatDialogConfig();
        const rect = this.data.position.nativeElement.getBoundingClientRect();
        matDialogConfig.width = '600px';
        matDialogConfig.height = '150px';
        matDialogConfig.position = { left: `${rect.left-300}px`, top: `${rect.bottom - 280}px` };
        this.dialogRef.updatePosition(matDialogConfig.position);
    }
    onNoClick(): void {
        this.dialogRef.close();
    }

    saveLinkAsName(): void {
        this.httpClient.post('/api/adminCompliance/saveLinkAsName',{
            name:this.saveName,
           transferState:this.data.transferStateString
        
        }).subscribe(response =>{
            if (response['success']) {
                this.snackbar.open('Success Saving Name','Dismiss', {               
                    verticalPosition: 'top', 
                    horizontalPosition: 'right',
                    duration: 2000,
                    panelClass:'success'
                });                
                this.dialogRef.close(true);     
            } else{
                this.snackbar.open('Error Saving Name','Dismiss', {               
                    verticalPosition: 'top', 
                    horizontalPosition: 'right',
                    duration: 2000,
                    panelClass:'error'
                });  
            }
        });
        }
}
