<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
    <mat-form-field>
        <mat-label>
            {{data.message}}
        </mat-label>
        <input matInput color="primary" [(ngModel)]="filename" />
    </mat-form-field>
</div>
<div style="float:right;margin:20px;">
    <input matButton style="width:70px;height:30px;margin:0px 10px;" type="button" value="Enter" [mat-dialog-close]="filename">
    <input matButton style="width:70px;height:30px;" type="button" value="Cancel" [mat-dialog-close]="false">
</div>