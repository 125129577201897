<mat-card>
    <mat-card-header>
        <button mat-stroked-button (click)="clearSQL()">Clear Query</button>
        <button mat-stroked-button (click)="resetSQL()">Reset</button>
        <button mat-stroked-button (click)="applySQL()">Apply</button>
        <button mat-stroked-button (click)="saveSQL()">Save</button>
        <button mat-stroked-button (click)="loadSQL()">Load</button>

        <form class="form" style="width: 50%;overflow: auto;">
            <mat-form-field class="full-width" appearance="fill">
                <mat-label>Enter Query Name</mat-label>
                <input [(ngModel)]="querySaveName" type="text" placeholder="Pick one" matInput [formControl]="querySaveForm" [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let name of querySaveNames" [value]="name">
                        {{name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </form>
    </mat-card-header>
    <mat-card-content style="width: 100%;">
        <div fxLayout="column" fxFlex="100%" style="width: 100%;row-gap: 10px;padding:5px;">
            <div *ngIf="dashService.advancedQuery.sql.length>0" fxLayout="row" fxFlex="100%" style="width: 100%">Applied Query :
                {{dashService.advancedQuery.sql}}</div>
            <div fxLayout="row" fxFlex="100%" style="width: 100%">
                <mat-form-field fxFlex="grow">
                    <mat-label>SQL Query</mat-label>
                    <textarea rows="5" cols="80" matInput [(ngModel)]="querySQL"></textarea>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxFlex="100%" style="width: 100%" *ngFor="let group of groups;let groupIndex=index;"
                style="width: 100%;column-gap: 10px;padding:5px;border:1px solid palevioletred;">
                <ng-template [ngTemplateOutlet]="groupTemplateRef" [ngTemplateOutletContext]="{ group: group , groupIndex:groupIndex,parentGroup:group}">
                </ng-template>
            </div>
        </div>
        <div style="height:0px;overflow: hidden;">
            {{groups | json}}
            <span></span>
        </div>
    </mat-card-content>
    <mat-card-footer>
        <button mat-stroked-button (click)="applySQL()">Apply</button>
    </mat-card-footer>
    <mat-card-footer>
        <div fxLayout="column" fxFlex="100%" style="margin-top: 30px;">
            <div fxLayout="row" fxFlex="100%">
                The currently used SQL
            </div>
            <div fxLayout="row" fxFlex="100%" style="margin-top: 20px;">
                {{dashService.whereHint}}
            </div>
        </div>
    </mat-card-footer>
</mat-card>


<ng-template #groupTemplateRef let-group="group" let-groupIndex="groupIndex" let-parentGroup="parentGroup">
    <div fxLayout="column" fxFlex="100%" style="width: 100%;">
        <div *ngIf="group!==parentGroup" fxLayout="row" fxFlex="100%" style="font-size:large;padding:10px">
            <div fxFlex="10%">
                Group
            </div>
            <div fxFlex="auto">
            </div>
            <div fxFlex="20%">
                <mat-form-field>
                    <mat-select [(ngModel)]="group.operator" (ngModelChange)="parseQuery()">
                        <mat-option *ngFor="let operator of operators" [value]="operator.name">{{operator.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxFlex="10%">
                <button (click)="deleteGroup(group,groupIndex,parentGroup)" mat-icon-button color="warn">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </div>
        <div fxLayout="row" fxFlex="100%" style="width: 100%;row-gap: 10px;padding:5px;;">
            <div fxLayout="column" fxFlex="100%" style="row-gap:10px">
                <div fxLayout="row" fxFlex="100%">
                    <button (click)="addGroup(group)" mat-stroked-button>Add Group</button>
                    <button cdkFocusInitial (click)="addStatement(group, groupIndex)" mat-stroked-button>Add Condition</button>
                </div>
                <div fxLayout="row" *ngFor="let statement of group.statements;let index=index;"
                    style="width: 100%;column-gap: 10px;padding:5px;border:1px solid greenyellow">
                    <mat-form-field fxFlex="10%" *ngIf="index">
                        <mat-select [(ngModel)]="statement.operator" (ngModelChange)="parseQuery()">
                            <mat-option *ngFor="let operator of operators" [value]="operator.name">{{operator.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex="20%">
                        <mat-select [(ngModel)]="statement.name" (ngModelChange)="parseQuery()">
                            <mat-option *ngFor="let field of fields" [value]="field.name">{{field.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex="auto" *ngIf="statement.name?.length>0">
                        <mat-select [(ngModel)]="statement.condition" [compareWith]="checkCondition">
                            <mat-option *ngFor="let condition of conditions" [value]="condition">{{condition.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex="auto" *ngIf="statement.condition.subConditions">
                        <mat-select [(ngModel)]="statement.subcondition" (ngModelChange)="parseQuery()" [compareWith]="checkSubCondition">
                            <mat-option *ngFor="let subcondition of statement.condition.subConditions" [value]="subcondition">
                                {{subcondition.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex=" 10%" *ngIf="statement.subcondition.name==='NEAR'">
                        <input matInput [(ngModel)]="statement.word1" (ngModelChange)="parseQuery()">
                    </mat-form-field>
                    <mat-form-field fxFlex=" 10%" *ngIf="statement.subcondition.name==='NEAR'">
                        <input matInput [(ngModel)]="statement.word2" (ngModelChange)="parseQuery()">
                    </mat-form-field>
                    <mat-form-field fxFlex="5%" *ngIf="statement.subcondition.name==='NEAR'">
                        <input matInput [(ngModel)]="statement.numWords" (ngModelChange)="parseQuery()" type=number>
                    </mat-form-field>
                    <mat-checkbox fxFlex="5%" *ngIf="statement.subcondition.name==='NEAR'" [(ngModel)]="statement.preserve"></mat-checkbox>
                    <mat-form-field fxFlex="auto" *ngIf="statement.name?.length>0 && statement.subcondition.name!=='NEAR'">
                        <input matInput [(ngModel)]="statement.value" (ngModelChange)="parseQuery()">
                    </mat-form-field>
                    <button (click)="deleteStatement(groupIndex,index)" mat-icon-button color="warn">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
                <div fxLayout="row" fxFlex="100%" style="width: 100%" *ngFor="let subGroup of group.groups;let subGroupIndex=index;"
                    style="width: 100%;column-gap: 10px;padding:5px;border:1px solid palevioletred;">
                    <ng-template [ngTemplateOutlet]="groupTemplateRef"
                        [ngTemplateOutletContext]="{ group: subGroup,groupIndex:subGroupIndex,parentGroup:group }">
                    </ng-template>
                </div>
            </div>
        </div>
    </div>