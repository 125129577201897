import { Component, OnInit,ViewChild } from '@angular/core';
import {DashService,CategoryList} from '../../services/dash.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {TrendWindowComponent} from '../../dialogs/trend-window/trend-window.component';
import { AuthService } from 'src/app/services/auth.service';


@Component({
    selector: 'app-dash-display-category-table',
    templateUrl: './dash-display-category-table.component.html',
    styleUrls: ['./dash-display-category-table.component.css']
})


export class DashDisplayCategoryTableComponent implements OnInit {
    displayedColumns: string[] = ['category', 'audioCount', 'audioWithKeywordCount', 'audioDuration','sentiment','select'];   
    categoryHeading ='';
    dataSource = new MatTableDataSource<CategoryList>();

    constructor(public dashService: DashService,
        public matDialog: MatDialog,
        public authService: AuthService
    ) { }
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort!: MatSort;

    ngAfterViewInit(): void {
       
       
    }
    ngOnInit(): void {
        this.dashService.categoryListUpdate.subscribe(categories => {            
            if (!(categories?.length>0))  {
                this.dataSource.data=[];
                this.categoryHeading = 'No Data';
                return;
            }  
            categories.forEach((compliance,index)=>{
                if (!compliance)  return;
                const complied = compliance.complied || 0;
                const complianceOpportunities = compliance.complianceOpportunities || 1;               
                categories[index].compliancePercent =complied/complianceOpportunities;
            });        
            this.dataSource.data = categories;
            this.categoryHeading = categories[0].CategoryHeading;
            if (this.dashService.isComplianceDash()) {
                this.displayedColumns  = ['category', 'audioCount', 'audioDuration','complianceOpportunities','complied','compliancePercent','select'];    
            }
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;            
        });     
    }
    selectTrends(attr: string,value: string): void{
        const dialogRef = this.matDialog.open(TrendWindowComponent, {
            height: '90%',
            width: '70%',
            data: {chartAttr:attr,chartValue:value},
            panelClass: 'trends-container'
        });
    }
    selectionChange(): void{
        this.dashService.reloadFilter();
        // this.dashService.categoryListUpdate.next(this.dashService.categoryList.values[this.dashService.selectedCategory]);
    }
    selectCategory(category: CategoryList): void{
        if (this.dashService.currentCategoryValues[category.CategoryHeading])
        {
            this.dashService.currentCategoryValues[category.CategoryHeading].values.push(category.category);
        }
        this.authService.userTrack({
              action: 'select_category',
              value: category.category,
              audioid: ''
            });
        this.dashService.reloadData();
    }

    sortData(): void{
        setTimeout(()=>{
            this.dashService.categoryList.values[this.dashService.selectedCategory] 
            = this.dataSource.sortData(this.dataSource.filteredData,this.dataSource.sort as MatSort);
        },2000);
    }

    applyFilter(event: KeyboardEvent): void {
        let filterValue =  (event.target as HTMLInputElement).value;
        filterValue = filterValue.trim(); 
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

}
