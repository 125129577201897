<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
    <p>This link will allow you to send your search criteria to another agent.</p>
    {{transferURL}}
</div>
<div style="text-align: center;float:center;margin:20px;">
    <input [cdkCopyToClipboard]="transferURL" matButton style="width:270px;height:30px;margin:10px 10px;" type="button" value="Copy to Clipboard"
        [mat-dialog-close]="">
</div>
<div *ngIf="data.saveAsNameOption">
    <div mat-dialog-content>
        <p>Enter Link name below to save using a name.</p>
    </div>
    <div style="text-align: center;float:center;margin:20px;">
        <input type="text" value="" [(ngModel)]="saveName">
    </div>
    <div style="text-align: center;float:center;margin:20px;">
        <button matTooltip="Save Link" color="primary" matTooltipPosition="above" mat-mini (click)="saveLinkAsName()">
            <mat-icon>save</mat-icon>
        </button>
    </div>
</div>