<mat-card>
    <mat-card-header>
        <button mat-button mat-dialog-close style=" margin-left: auto;">Cancel</button>
        <button (click)="saveScript()" mat-button cdkFocusInitial>Save</button>
    </mat-card-header>
    <mat-card-content>
        <mat-form-field appearance="fill">
            <mat-label>Script Name</mat-label>
            <input [(ngModel)]="scriptName" matInput />
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Script Text</mat-label>
            <textarea [(ngModel)]="scriptText" cdkTextareaAutosize matInput></textarea>
        </mat-form-field>
    </mat-card-content>
    <mat-card-footer>
        <button mat-button mat-dialog-close style=" margin-left: auto;">Cancel</button>
        <button (click)="saveScript()" mat-button>Save</button>
    </mat-card-footer>
</mat-card>