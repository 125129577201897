import { Component, OnInit,OnDestroy } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import {GridOptions,ModuleRegistry, RowNode}  from '@ag-grid-community/core';
import {ICellRendererAngularComp} from '@ag-grid-community/angular';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import {RichSelectModule} from '@ag-grid-enterprise/rich-select';
import { MatSnackBar } from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {AddScriptWindowComponent} from '../../dialogs/add-script-window/add-script-window.component';
import {AddScriptComplianceWindowComponent} from '../../dialogs/add-script-compliance-window/add-script-compliance-window.component';
import {ConfirmBoxComponent} from '../../dialogs/confirm-box/confirm-box.component';
import {FormatScriptBoxComponent} from '../../dialogs/formatScript-box/formatScript-box.component';
import * as dayjs from 'dayjs';

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    MasterDetailModule,
    RichSelectModule
]);
@Component({
    selector: 'btn-cell-renderer',
    template: `
      <button (click)="btnClickedHandler($event)">{{params.colDef.headerName}}</button>
    `
})
export class BtnCellRenderer implements ICellRendererAngularComp {
    public params: any;
    refresh: any;
  
    agInit(params: any): void {
        this.params = params;
    }
  
    btnClickedHandler(event: any): void {
        this.params.clicked(this.params.data);
    }
  
   
}
@Component({
    selector: 'app-admin-script-compliance',
    templateUrl: './admin-script-compliance.component.html',
    styleUrls: ['./admin-script-compliance.component.css']
})



export class AdminScriptComplianceComponent implements OnInit, OnDestroy {
   
    grid!: GridOptions;
    detailRow;
    gridOptions: GridOptions = {
        masterDetail: true,
        getRowNodeId :(data)=> data.id,
        onRowGroupOpened : (params: any)=> {
            if (!params.expanded) return;
            setTimeout(()=>{
                const saveButton = document.querySelector('#add-compliance-button-'+params.data.id);        
                if (saveButton && !saveButton['listenerAdded'] ) {
                    saveButton['listenerAdded']=true;
                    saveButton.addEventListener('click',this.addScriptCompliance.bind(this,params));
                }
            },100);
      
        },
        columnDefs :[
            {field:'scriptName',maxWidth:500, cellRenderer: 'agGroupCellRenderer',                      
                resizable: true,
                sortable: true, 
                filter: true,
                editable: true,
                cellEditor: 'agTextCellEditor' },
                
            { field: 'version',                
                resizable: true,
                sortable: true, 
                maxWidth: 300, 
                filter: true },
            { field: 'firstRun',                
                resizable: true,
                sortable: true, 
                editable: true,
                maxWidth: 300, 
                filter: true },
            {field:'scriptText',                
                resizable: true,
                sortable: true, 
                filter: true,
                editable: true,
                cellEditor: 'agLargeTextCellEditor',
                cellEditorParams: {maxLength: 50000,rows:30,cols:100}              
            },{
                headerName:'Delete',
                hide: true,
                field: 'delete',
                cellRenderer: 'btnCellRenderer',
                cellRendererParams: {
                    clicked: (field: any)=>{
                        this.deleteScript(field);
                    }
                },
                minWidth: 150,
                maxWidth: 150
            }
        ],
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer
        },
        
        rowModelType:'clientSide',
        suppressHorizontalScroll: true,
        detailRowAutoHeight: true,
        detailCellRendererParams: {

            // provide the Grid Options to use on the Detail Grid
            detailGridOptions: {
                columnDefs: [ { field: 'seq',                
                    resizable: true,
                    sortable: true, 
                    maxWidth: 100,
                    filter: true ,
                    editable: true,
                    cellEditor: 'agTextCellEditor' },
                { field: 'complianceName',                
                    resizable: true,
                    sortable: true, 
                    maxWidth: 300, 
                    filter: true },
                { field: 'complianceHint',                
                    resizable: true,
                    sortable: true, 
                    filter: true,
                    maxWidth: 370, 
                    editable: true,
                    cellEditor: 'agTextCellEditor'},
                { field: 'complianceText',                
                    resizable: true,
                    sortable: true, 
                    maxWidth: 370, 
                    filter: true,
                    editable: true,
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorParams: {maxLength: 50000}    },
                { field: 'complianceText2',                
                    resizable: true,
                    sortable: true, 
                    maxWidth: 370, 
                    filter: true,
                    editable: true,
                    cellEditor: 'agLargeTextCellEditor' ,
                    cellEditorParams: {maxLength: 50000}   },
                { field: 'gauges',                
                    resizable: true,
                    sortable: true, 
                    maxWidth: 370, 
                    filter: true,
                    editable: true,
                    cellEditor: 'agLargeTextCellEditor'    },
                { field: 'selectionAttr',                
                    resizable: true,
                    sortable: true, 
                    maxWidth: 370, 
                    filter: true,
                    editable: true,
                    cellEditor: 'agTextCellEditor' },
                { field: 'selectionAttrVal',width:170,                
                    resizable: true,
                    sortable: true, 
                    filter: true,
                    editable: true,
                    cellEditor: 'agTextCellEditor' },
                { field: 'decisionThreshold',width:200,                
                    resizable: true,
                    sortable: true, 
                    filter: true,
                    editable: true,
                    cellEditor: 'agTextCellEditor' },
                { 
                    headerName: 'Enabled', width: 120, field: 'enabled', editable: true, 
                    cellEditor:'agRichSelectCellEditor',
                        
                    cellEditorParams: { 
                        values: ['Yes','No']
                    },
                    valueSetter: function(params: { data: { enabled: boolean | undefined }; newValue: string }) {
                        params.data.enabled = [{name:'Yes',id:true},{name:'No',id:false}].find(refData => refData.name === params.newValue)?.id;
                        return true;
                    }, 
                    valueGetter: function(params: { data: { enabled: any } }) {
                        if (params.data.enabled) return 'Yes';
                        return 'No';
                    }
                },{
                    headerName:'Delete',
                    field: 'delete',
                    hide: false,
                    cellRenderer: 'btnCellRenderer',
                    cellRendererParams: {
                        clicked: (field: any)=>{
                            this.deleteScriptCompliance(field);
                        }
                    },
                    minWidth: 100,
                    maxWidth: 100
                },{
                    headerName:'Format Script',
                    headerValueGetter: ()=>'',                      
                    field: 'formatScript',                        
                    cellRenderer: 'btnCellRenderer',
                    cellRendererParams: {
                        clicked: (field: any)=>{
                            this.formatScriptCompliance(field);
                        }
                    },
                    minWidth: 150,
                    maxWidth: 150
                }
                    
                ],
                onCellValueChanged:((event: any)=>{
                    this.onCellValueChanged(event);
                }),
                onGridReady: (params: { api: { sizeColumnsToFit: () => void } }): void =>{          
                    params.api.sizeColumnsToFit();
                },
                frameworkComponents: {
                    btnCellRenderer: BtnCellRenderer
                }
            },
            template: (params)=>{
                return  `
            <div class="ag-details-row ag-details-row-auto-height">
            <button id="add-compliance-button-${params.data.id}" style="margin-left:auto;height:30px;margin-bottom:10px;margin-right:10px;display:block">Add a Compliance</button>
            <div ref="eDetailGrid" class="ag-details-grid ag-details-grid-auto-height"/>
            </div>`;
            },           
            getDetailRowData: (params: { data: { id: any }; successCallback: (arg0: { id: number; scriptId: number; complianceName: string;
                complianceText: string; complianceText2: string; selectionAttr: string; selectionAttrVal: string; complianceResultAttr: string; complianceConfAttr: string;
                complianceTimeAttr: string;gauges: string; }[]) => void; }) =>{
                const script = params.data.id;
                const details = this.detailData.filter((row)=>row.scriptId===script);
                params.successCallback(details);
            },
            refreshStrategy:'rows'
        }
    };
    masterData: {id: number;accountId: number;scriptName: string;version: string;firstRun: string;scriptText: string}[] = [];
    detailData: { 
        id: number;
        scriptId: number;
        complianceName: string;
        complianceText: string;
        complianceText2: string;
        selectionAttr: string;
        selectionAttrVal: string;
        complianceResultAttr: string;
        complianceConfAttr: string;
        complianceTimeAttr: string;
        gauges: string;
    }[] = [];       

    constructor(
        private httpClient: HttpClient,
        private spinner: NgxSpinnerService,
        private snackbar: MatSnackBar,
        private matDialog: MatDialog) { }
   

    resizeColumns(grid: GridOptions): void {
        if (grid && grid.columnApi){          
            const colIds = grid.columnApi
                .getAllDisplayedColumns()
                .map(col => col.getColId());
            grid.columnApi.autoSizeColumns(colIds);
            grid.api?.sizeColumnsToFit();
        }
    }

    ngOnInit(): void {
       
    }

    addScript(): void{
        this.matDialog.open(AddScriptWindowComponent, {
            height: '70%',
            width: '50%',           
            panelClass: 'script-container'
        }).afterClosed().subscribe(result => {
            if (result) this.getData();
        });
    }

    addScriptCompliance(params: {node: any;data: {id: number;scriptName: string}}): void{
        this.matDialog.open(AddScriptComplianceWindowComponent, {
            height: '70%',
            width: '50%',           
            panelClass: 'script-container',
            data:{scriptId:params.data.id,scriptName:params.data.scriptName}
        }).afterClosed().subscribe(result => {
            if (result) {
                this.detailRow = params?.data.id;
                this.getData();
            }            
        });
    }

    deleteScriptCompliance(field: { complianceName: string; id: any[] }): void{
        this.matDialog.open(ConfirmBoxComponent, {
            height: '20%',
            width: '30%',           
            panelClass: 'confirm-container',
            data: {
                title: 'Confirm Delete Script',
                message: 'Are you sure, you want to delete Compliance : '+field.complianceName
            }
        }).afterClosed().subscribe(result => {
            if (!result) return;
            this.httpClient.post('/api/adminCompliance/deleteScriptCompliance',{id:field.id[1]}).subscribe(response =>{
                if (response['success']) {
                    this.snackbar.open('Success Deleting Compliance','Dismiss', {               
                        verticalPosition: 'top', 
                        horizontalPosition: 'right',
                        duration: 2000,
                        panelClass:'success'
                    });  
                    this.detailRow=field.id[0];
                    this.getData();
                } else{
                    this.snackbar.open('Error Deleting Compliance','Dismiss', {               
                        verticalPosition: 'top', 
                        horizontalPosition: 'right',
                        duration: 2000,
                        panelClass:'error'
                    });  
                }
            });
        });
    }

    deleteScript(field: { scriptName: string; id: any }): void{
        this.matDialog.open(ConfirmBoxComponent, {
            height: '20%',
            width: '30%',           
            panelClass: 'confirm-container',
            data: {
                title: 'Confirm Delete Script',
                message: 'Are you sure, you want to delete Script : '+field.scriptName
            }
        }).afterClosed().subscribe(result => {
            if (!result) return;
            this.httpClient.post('/api/adminCompliance/deleteScript',{id:field.id}).subscribe(response =>{
                if (response['success']) {
                    this.snackbar.open('Success Deleting Script','Dismiss', {               
                        verticalPosition: 'top', 
                        horizontalPosition: 'right',
                        duration: 2000,
                        panelClass:'success'
                    });  
                    this.getData();
                } else{
                    this.snackbar.open('Error Deleting Script','Dismiss', {               
                        verticalPosition: 'top', 
                        horizontalPosition: 'right',
                        duration: 2000,
                        panelClass:'error'
                    });  
                }
            });
        });
    }

    onCellValueChanged(event: { data: { scriptId: any; scriptName: string; complianceText2: string; firstRun: string | number | Date } }): void{
        this.httpClient.post('/api/adminCompliance/validateScriptCompliance', { data: event.data.complianceText2,scriptId:event.data.scriptId },
           ).subscribe(response => {
               if (!response['success']) {
                     this.snackbar.open(response['data'],'Dismiss', {               
                verticalPosition: 'top', 
                horizontalPosition: 'right',
                duration: 2000,
                panelClass:'error'
                   }); 
                   return;
               } else {
                     if (!event.data.scriptId){
            event.data.scriptName=event.data.scriptName.replace(' ','').replace('-','');
            const firstRun = new Date(event.data.firstRun);
            event.data.firstRun = dayjs(firstRun).toString();
            this.httpClient.post('/api/adminCompliance/updateAccountScript',event.data).subscribe(response =>{
                if (response['success']) {
                    this.snackbar.open('Success Updating Script','Dismiss', {               
                        verticalPosition: 'top', 
                        horizontalPosition: 'right',
                        duration: 2000,
                        panelClass:'success'
                    });  
                    this.getData();
                } else{
                    this.snackbar.open('Error Updating Script','Dismiss', {               
                        verticalPosition: 'top', 
                        horizontalPosition: 'right',
                        duration: 2000,
                        panelClass:'error'
                    });  
                }
            });
        } else {
   
            this.httpClient.post('/api/adminCompliance/updateAccountScriptCompliance',event.data).subscribe(response =>{
                if (response['success']) {
                    this.snackbar.open('Success Updating Compliance','Dismiss', {               
                        verticalPosition: 'top', 
                        horizontalPosition: 'right',
                        duration: 2000,
                        panelClass:'success'
                    });                     
                } else{
                    this.snackbar.open('Error Updating Compliance','Dismiss', {               
                        verticalPosition: 'top', 
                        horizontalPosition: 'right',
                        duration: 2000,
                        panelClass:'error'
                    });  
                }
            });
        }
                   
            }
        }, error => {
                 this.snackbar.open('Error Updating','Dismiss', {               
                verticalPosition: 'top', 
                horizontalPosition: 'right',
                duration: 2000,
                panelClass:'error'
            }); ;
        });      
    }

    getData(): void {
        this.spinner.show(); 
        this.httpClient.get(
            '/api/adminCompliance/getAccountScripts', {
         
            })
            .subscribe((response: any) => {
                this.spinner.hide();
                let firstID=-1;
                this.masterData = [];
                response.data.forEach((row: { id: any[]; accountId: any; scriptName: any; version: any; firstRun: string | number | Date; scriptText: any })=>{
                    if (row.id[0]!==firstID){
                        firstID = row.id[0];
                        this.masterData.push({
                            id: row.id[0],
                            accountId: row.accountId,
                            scriptName: row.scriptName,
                            version:row.version,
                            firstRun:dayjs(new Date(row.firstRun)).format('YYYY-MM-DD'),
                            scriptText: row.scriptText
                        });
                    }
                });
                this.grid.api?.setRowData( this.masterData); 
                this.detailData = response.data; 
                this.resizeColumns(this.grid);
                if (this.detailRow) {
                    setTimeout(()=>{
                        const node = this.grid.api?.getRowNode(this.detailRow);
                        this.grid.api?.setRowNodeExpanded(node as RowNode, true);
                        this.detailRow=null;
                    },50);
                    
                }
            });
    }

    onGridReady(grid: GridOptions): void {
        this.grid=grid;
        this.getData();
    }
    
    ngOnDestroy(): void{
        if (this.grid) this.grid.api?.destroy();
    }

    formatScriptCompliance(row: { complianceText: string; complianceName: any }): void{
        let body: { name: any };

        try
        {
            body =JSON.parse(row.complianceText);}
        catch (err) {
            this.snackbar.open('Not Valid JSON','Dismiss', {               
                verticalPosition: 'top', 
                horizontalPosition: 'right',
                duration: 2000,
                panelClass:'error'
            }); 
            return;
        }
        body.name = row.complianceName;

        this.httpClient.post('/api/adminCompliance/formatScriptCompliance',body
        
        ).subscribe((response: any)=>{
            this.matDialog.open(FormatScriptBoxComponent, {
                height: '70%',
                width: '50%',           
                panelClass: 'script-container',
                data: response.data
            });
        }  ,
        (error)=>{
            debugger;
        }   
        );
    }
}
