import { Component, OnInit, HostListener ,ViewChild} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import { HttpClient } from '@angular/common/http';
import {DashService} from '../../services/dash.service';
import {MatDialog} from '@angular/material/dialog';
import {ServerSideStoreType } from '@ag-grid-community/core';

import {FilenameBoxComponent} from '../../dialogs/filename-box/filename-box.component';
import {CopylinkBoxComponent} from '../../dialogs/copylink-box/copylink-box.component';
import {BuildQueryComponent} from '../../dialogs/query-builder/query-builder.component';
import { TransferService } from 'src/app/services/transfer.service';
import * as dayjs from 'dayjs';

@Component({
    selector: 'app-trends',
    templateUrl: './trends.component.html',
    styleUrls: ['./trends.component.css']
})
export class TrendsComponent implements OnInit {
   
   

    constructor(
        private authService: AuthService,
        private httpClient: HttpClient,
        public dashService: DashService,
        private matDialog: MatDialog,
        private transferService: TransferService
    ) { }

    ngOnInit(): void {
        this.authService.reConnect();
    }

}
