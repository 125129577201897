import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import { ThrowStmt } from '@angular/compiler';
import { ParamMap, Router, ActivatedRoute } from '@angular/router';
import {getGuid} from '../services/utility.service';
// Generated by https://quicktype.io

export interface ComplianceScript {
    success: boolean;
    data:    Compliance[];
}

export interface Compliance {
    id:                   number[];
    accountId:            number;
    scriptName:           string;
    scriptText:           string;
    scriptId:             number;
    complianceName:       string;
    complianceText:       string;
    complianceText2:       string;
    selectionAttr:        string;
    selectionAttrVal:     string;
    complianceResultAttr: string;
    complianceConfAttr:   string;
    complianceTimeAttr:   string;
    gauges:               null | string;
    seq:                  number | null;
    enabled:              boolean | null;
    decisionThreshold:    number | null;
    complianceHint:       null | string;
}

export interface ComplianceHint {
    [key: string]: string;   
}

@Injectable({
    providedIn: 'root'
})

export class ComplianceService {
    complianceHints: ComplianceHint={};

    constructor(
        public httpClient: HttpClient,
        public authService: AuthService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.httpClient.get(
            '/api/adminCompliance/getAccountScripts', {
         
            })
            .subscribe((response: any) => {
                response.data.forEach(element => {
                    this.complianceHints[element.complianceResultAttr]=element.complianceHint;
                });          
            });
    }


    
}

