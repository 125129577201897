<mat-card>
    <mat-card-header>
        <button mat-button mat-dialog-close style=" margin-left: auto;">Cancel</button>
        <button (click)="saveScriptCompliance()" mat-button cdkFocusInitial>Save</button>
    </mat-card-header>
    <mat-card-content>
        <mat-form-field appearance="fill">
            <mat-label>Compliance Sequence</mat-label>
            <input [(ngModel)]="seq" matInput />
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Compliance Name</mat-label>
            <input [(ngModel)]="complianceName" matInput />
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Compliance Text</mat-label>
            <textarea [(ngModel)]="complianceText" cdkTextareaAutosize matInput></textarea>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Compliance Text2</mat-label>
            <textarea [(ngModel)]="complianceText2" cdkTextareaAutosize matInput></textarea>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Gauges</mat-label>
            <textarea [(ngModel)]="gauges" cdkTextareaAutosize matInput></textarea>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Compliance Hint</mat-label>
            <input [(ngModel)]="complianceHint" matInput />
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Selection Attribute</mat-label>
            <input [(ngModel)]="selectionAttr" matInput />
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Selection Attribute Value</mat-label>
            <input [(ngModel)]="selectionAttrVal" matInput />
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Decision Threshold</mat-label>
            <input [(ngModel)]="decisionThreshold" matInput />
        </mat-form-field>
    </mat-card-content>
    <mat-card-footer>
        <button mat-button mat-dialog-close style=" margin-left: auto;">Cancel</button>
        <button (click)="saveScriptCompliance()" mat-button>Save</button>
    </mat-card-footer>
</mat-card>