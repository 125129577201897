<div class="panel-body" style="padding-bottom:30px;background-color:rgb(230, 230, 230)">
    <div fxLayout="row">
        <div fxFlex="1%"></div>
        <div fxFlex="200px">
            <h5 class="ng-binding">Date
                <span class="semi-bold ">Selected</span>
            </h5>
            <app-dash-date-picker></app-dash-date-picker>
        </div>
        <div fxFlex="2%">
        </div>

        <div fxFlex="1%">
        </div>
    </div>
</div>