import { Component, OnInit } from '@angular/core';
import {DashService,CurrentComplianceValues} from '../../services/dash.service';

@Component({
    selector: 'app-dash-display-compliances',
    templateUrl: './dash-display-compliances.component.html',
    styleUrls: ['./dash-display-compliances.component.css']
})
export class DashDisplayCompliancesComponent implements OnInit {

    constructor(public dashService: DashService) { }

    ngOnInit(): void {
    }

    removeCompliance(compliance: CurrentComplianceValues): void{
        this.dashService.currentComplianceValues = 
        this.dashService.currentComplianceValues.filter(item => {
            const same = item.compliance === compliance.compliance && item.operator===compliance.operator && compliance.stereo===item.stereo;
            return !same;
        });

        this.dashService.reloadData();    
    }
}
