import { Component, OnInit ,Inject} from '@angular/core';
import {  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-formatScript-box',
    templateUrl: './formatScript-box.component.html',
    styleUrls: ['./formatScript-box.component.css']
})

export class FormatScriptBoxComponent implements OnInit {  

    dataString='';

    constructor(
        public dialogRef: MatDialogRef<FormatScriptBoxComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit(): void {             
        this.data=JSON.parse(this.data);  
        this.dataString = JSON.stringify(this.data, null, 2);
    }
    onNoClick(): void {
        this.dialogRef.close();
    }
}
