/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import {DashService} from '../../services/dash.service';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import * as dayjs from 'dayjs';

@Component({
    selector: 'app-dash-date-picker',
    templateUrl: './dash-date-picker.component.html',
    styleUrls: ['./dash-date-picker.component.css']
})
export class DashDatePickerComponent implements OnInit {
    faCalendar = faCalendarAlt;
    ranges={'Today': [dayjs(), dayjs()],
        'Yesterday': [dayjs().subtract(1, 'days'), dayjs().subtract(1, 'days')],
        'Last 7 Days': [dayjs().subtract(6, 'days'), dayjs()],
        'Last 30 Days': [dayjs().subtract(29, 'days'), dayjs()],
        'Last 90 Days': [dayjs().subtract(90, 'days'), dayjs()],
        'Last 365 Days': [dayjs().subtract(365, 'days'), dayjs()],
        'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
        'Last Month': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')]};
 
    constructor(public dashService: DashService) {
       
    }

    ngOnInit(): void {
    }

    datesUpdated(): void {
        this.dashService.reloadData();
    }
}
