<div fxLayout="row wrap" fxLayoutGap="20px" *ngIf="dashService.complianceTotals.totals.length>0">
    <div fxFlex="10%" class="widget-8 text-lucid border5 bg-success">
        <div fxLayout="row" class="container-xs-height full-height">
            <div fxFlex="100%" fxLayoutAlign="center" style="margin-top:5%;">
                <span class="item-count"> &#032;&nbsp;</span>
            </div>
        </div>
        <div fxLayout="row" class="row-xs-height">
            <div fxFlex="100%" fxLayoutAlign="center">
                <h5 matTooltip="Click for Totals" (click)="expandTotals()" class="" style="text-align: center;">Totals </h5>
            </div>
        </div>
    </div>
    <div fxFlex="auto" class="widget-8 text-lucid border5 bg-success " *ngFor="let item of dashService.complianceTotals.totals">
        <div fxLayout="row" class="container-xs-height full-height">
            <div fxFlex="100%" fxLayoutAlign="center" style="margin-top:5%;">
                <span class="item-count">{{item.splitdata}}</span>
            </div>
        </div>
        <div fxLayout="row" class="row-xs-height">
            <div fxFlex="100%" fxLayoutAlign="center">
                <h5 class="" style="text-align: center;">{{(item.pct*100).toFixed(2)}}% </h5>
            </div>
        </div>
    </div>
</div>
<div fxLayout="row" class="row-xs-height">
    <div fxFlex="5%">

    </div>
    <div *ngIf="drillOpenLoad" style="margin-bottom: 30px;margin-top: -30px;">
        Loading...
    </div>
</div>
<div *ngIf="drillOpen" style="margin-bottom:20px">
    <div fxLayout="row wrap" fxLayoutGap="20px" *ngFor="let item of drillDown">
        <div fxFlex="15%" class="widget-9 text-lucid2 border5 bg-success">

            <div fxLayout="row" class="row-xs-height">
                <div fxFlex="100%" fxLayoutAlign="center">
                    <span class="" style="text-align: center;">{{item.catval}} </span>
                </div>
            </div>
        </div>
        <div fxFlex="auto" class="widget-9 text-lucid2 border5 bg-success " *ngFor="let key of item.splitkey">

            <div fxLayout="row" class="row-xs-height">
                <div fxFlex="100%" fxLayoutAlign="center">
                    <span class="" style="text-align: center;">{{key.pct?(key.pct*100).toFixed(2)+'%':' &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp;'}}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>