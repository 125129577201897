<div fxLayout="row wrap" fxLayoutGap="20px" style="">
    <div fxFlex="23%" class="widget-8 tiles blue border5">
        <div class="tiles-body st-right" style="height: 120px;">
            <div class="tiles-title text-black ng-binding">LUCIDCX DASHBOARD</div>
            <div class="">
                <div class="wrapper transparent">
                    <span class="item-count semi-bold ng-binding">TotalCalls</span>
                </div>
            </div>
            <div class="">
                <div *ngIf="dashService?.dashTotals" class="wrapper">
                    <span class="item-count semi-bold ng-binding" style="font-size: 30px;"
                        data-animation-duration="700">{{dashService.dashTotals.audioCount}}</span>
                </div>
            </div>
            <div>
                <span class="hide label2 font-montserrat m-r-5 ng-binding">0% QScore</span>
            </div>
            <div>
                <p *ngIf="dashService?.dashTotals" style="text-align: center;" class="small hint-text text-white">
                    <span class="font-montserrat ng-binding">{{dashService.dashTotals.reviewedCount}} Calls Inspected</span>
                </p>
            </div>
        </div>
        <div class="widget-8-chart line-chart st-left" ng-class="authservice.permission.holdTimeData=='true'?'short':''">
        </div>
    </div>
    <div fxFlex="23%" class="widget-8 panel border5 bg-success ">
        <div fxLayout="row" class="container-xs-height full-height">
            <div fxFlex="100%">
                <div fxLayout="row" class="row-xs-height">
                    <div class="col-xs-height col-top">
                        <div class="panel-heading top-left top-right" style="padding:3px 1px 4px 20px; text-align: center;">
                            <div class="panel-title hint-text p-r-20">
                                <span class="font-montserrat fs-11 uppercase">Experience</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" *ngIf="dashService?.dashTotals" class="row-xs-height">
                    <div fxFlex="100%" class="col-xs-height col-top relative">
                        <div fxLayout="row" class="row">
                            <div fxFlex="100%" fxLayoutAlign="center" class="">
                                <div style="margin-top:2px" class="star-rating2 ng-scope">
                                    <fa-icon class="rating-hide" [icon]="faFrown" style=" font-size: 40px;"></fa-icon>
                                    <fa-icon class="rating-hide" [icon]="faFrown" style=" font-size: 34px;"></fa-icon>
                                    <fa-icon class="rating-hide" [icon]="faMeh" style=" font-size: 30px;"></fa-icon>
                                    <fa-icon class="rating-hide" [icon]="faSmile" style=" font-size: 34px;"></fa-icon>
                                    <fa-icon class="rating-hide" [icon]="faSmile" style=" font-size: 40px;"></fa-icon>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="row" class="row">
                            <div fxFlex="100%" fxLayoutAlign="center">
                                <h3 class="text-white" style="text-align: center;">{{dashService?.dashTotals?.avgSentiment?.toFixed(2)}} </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div fxFlex="23%" class="widget-8 panel border5 bg-success ">
        <div fxLayout="row" class="container-xs-height full-height">
            <div fxFlex="100%">
                <div fxLayout="row" class="row-xs-height">
                    <div class="col-xs-height col-top">
                        <div class="panel-heading top-left top-right" style="padding:3px 1px 4px 20px; text-align: center;">
                            <div class="panel-title hint-text p-r-20">
                                <span class="font-montserrat fs-11 uppercase">CONTACTS WITH KEYWORD</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="center" class="text-align: center;" *ngIf="dashService?.dashTotals">
                    <span
                        class="label2 font-montserrat m-r-5">{{dashService.displayPercent(dashService.dashTotals.audioWithKeywordCount/dashService.dashTotals.audioCount*100)}}%</span>
                </div>
                <div fxLayout="row" fxLayoutAlign="center" class="" *ngIf="dashService?.dashTotals">
                    <h3 style="margin: 8px;" class="text-white">{{dashService.dashTotals.audioWithKeywordCount}}</h3>
                </div>
                <div fxLayout="row" fxLayoutAlign="center" class="" *ngIf="dashService?.dashTotals">
                    <span class="font-montserrat">{{'of'}} {{dashService.dashTotals.audioCount}} {{dashService.formatMediaType(1)}}</span>
                </div>
                <div fxLayout="row" fxLayoutAlign="center" class="" *ngIf="dashService?.dashTotals">
                    <span class="font-montserrat">{{dashService.dashTotals.audioWithKeywordReviewedCount+0}} {{'Inspected'}}</span>
                </div>
            </div>
        </div>

    </div>
    <div fxFlex="23%" class="widget-8 panel border5 bg-success ">
        <div fxLayout="row" class="container-xs-height full-height">
            <div fxFlex="100%">
                <div fxLayout="row" class="row-xs-height">
                    <div class="col-xs-height col-top">
                        <div class="panel-heading top-left top-right" style="padding:3px 1px 4px 20px; text-align: center;">
                            <div class="panel-title hint-text p-r-20">
                                <span class="font-montserrat fs-11 uppercase">CONTACTS WITH OUT KEYWORD</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="center" class="text-align: center;" *ngIf="dashService?.dashTotals">
                    <span
                        class="label2 font-montserrat m-r-5">{{dashService.displayPercent((1-dashService.dashTotals.audioWithKeywordCount/dashService.dashTotals.audioCount)*100)}}%</span>
                </div>
                <div fxLayout="row" fxLayoutAlign="center" class="" *ngIf="dashService?.dashTotals">
                    <h3 style="margin: 8px;" class="text-white">{{dashService.dashTotals.audioCount-dashService.dashTotals.audioWithKeywordCount}}</h3>
                </div>
                <div fxLayout="row" fxLayoutAlign="center" class="" *ngIf="dashService?.dashTotals">
                    <span class="font-montserrat">{{'of'}} {{dashService.dashTotals.audioCount}} {{dashService.formatMediaType(1)}}</span>
                </div>
                <div fxLayout="row" fxLayoutAlign="center" class="" *ngIf="dashService?.dashTotals">
                    <span class="font-montserrat">{{dashService.dashTotals.reviewedCount-dashService.dashTotals.audioWithKeywordReviewedCount}}
                        {{'Inspected'}}</span>
                </div>
            </div>
        </div>

    </div>
    <div fxFlex="23%" class="widget-8 panel border5 bg-success ">
        <div fxLayout="row" class="container-xs-height full-height">
            <div fxFlex="100%">
                <div fxLayout="row" class="row-xs-height">
                    <div class="col-xs-height col-top">
                        <div class="panel-heading top-left top-right" style="padding:3px 1px 4px 20px; text-align: center;">
                            <div class="panel-title hint-text p-r-20">
                                <span class="font-montserrat fs-11 uppercase">Total Keyword Mentions</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="center" class="" *ngIf="dashService?.dashTotals">
                    <h3 style="margin: 8px;" class="text-white">{{dashService.dashTotals.keywordCount}}</h3>
                </div>
                <div fxLayout="row" fxLayoutAlign="center" class="" *ngIf="dashService?.dashTotals">
                    <span class="font-montserrat">{{'of'}} {{dashService.dashTotals.audioCount}} {{dashService.formatMediaType(1)}}</span>
                </div>

            </div>
        </div>

    </div>
    <div fxFlex="23%" *ngFor="let source of dashService?.categoryList?.values?.source" class="widget-8 panel border5 bg-success ">
        <div fxLayout="row" class="container-xs-height full-height">
            <div fxFlex="100%">
                <div fxLayout="row" class="row-xs-height">
                    <div class="col-xs-height col-top">
                        <div class="panel-heading top-left top-right" style="padding:3px 1px 4px 20px; text-align: center;">
                            <div class="panel-title hint-text p-r-20">
                                <span class="font-montserrat fs-11 uppercase">{{'Total '+ dashService.formatSource(source.category,2)}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="center" class="" *ngIf="dashService?.dashTotals">
                    <h3 style="margin: 8px;" class="text-white">
                        {{dashService.dashTotalsSource[source.category].audioCount}}</h3>
                </div>
                <div fxLayout="row" fxLayoutAlign="center" class="" *ngIf="dashService?.dashTotals">
                    <span class="font-montserrat">{{dashService.formatTime(dashService.dashTotalsSource[source.category].audioDuration,source.category)}}
                        duration</span>
                </div>

                <div fxLayout="row" fxLayoutAlign="center" class="text-align: center;" *ngIf="dashService?.dashTotals">
                    <span class="label2 font-montserrat m-r-5">Avg :
                        {{dashService.formatTime(dashService.dashTotalsSource[source.category].audioDuration/dashService.dashTotalsSource[source.category].audioCount,source.category)}}
                    </span>
                </div>
            </div>
        </div>

    </div>
</div>