<div fxLayout="row" style="background-color:rgba(245, 245, 245, 0.7)">

    <div fxFlex="2%">
    </div>
    <div fxFlex="95%">
        <mat-card>
            <mat-card-header>
                <span class='header'>Admin Script Compliance</span>
                <button (click)="addScript()" style="margin-left:auto;height:30px;margin-top:20px;margin-right:10px">Add a Script</button>
            </mat-card-header>
            <mat-card-content>
                <div style="display: flex; flex-direction: row; height: 100%;">
                    <div class="admin-script-grid" style="overflow: hidden; flex-grow: 1;">
                        <ag-grid-angular style="height: 700px;width:auto" class="ag-theme-alpine" (gridReady)="onGridReady($event)" [gridOptions]="gridOptions"
                            rowModelType="clientSide" (cellValueChanged)="onCellValueChanged($event)">
                        </ag-grid-angular>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div fxFlex="2%">
    </div>

</div>