import { Component, OnInit,ViewChild } from '@angular/core';
import {DashService,ComplianceList} from '../../services/dash.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {ComplianceService} from '../../services/complianceService.service';
import { AuthService } from 'src/app/services/auth.service';
@Component({
    selector: 'app-dash-display-compliance-table',
    templateUrl: './dash-display-compliance-table.component.html',
    styleUrls: ['./dash-display-compliance-table.component.css']
})


export class DashDisplayComplianceTableComponent implements OnInit {
    displayedColumns: string[] = ['complianceName', 'complianceOpportunities',  'complied','compliancePercent','select'];   
    categoryHeading ='';
    dataSource = new MatTableDataSource<ComplianceList>();

    constructor(public dashService: DashService,public authService: AuthService,
        public complianceService: ComplianceService) {

            
    }
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }
    ngOnInit(): void {
        this.dashService.complianceListUpdate.subscribe(compliances => {  
            if (!compliances) return;           
            compliances.forEach((compliance,index)=>{
                compliances[index].compliancePercent =compliance.complied/compliance.complianceOpportunities;
            });                         
            this.dataSource.data = compliances;           
        });     
    }

    onHover(event): void{
        debugger;
    }
    selectCompliance(complianceList: ComplianceList,selection: number): void{  
        let operator='=';
        if (selection===1) operator = '+';
        if (selection===-1) operator = '-';
        const complianceSelection = {
            compliance: complianceList.complianceResultAttr.replace(/\\'/g, '\''),
            operator: operator,
            stereo: ''
        };
        if (this.dashService.currentComplianceValues)
            this.dashService.currentComplianceValues.push(complianceSelection);
        else
            this.dashService.currentComplianceValues = [complianceSelection];
         this.authService.userTrack({
          action: 'select_compliance',
          value: complianceList.complianceResultAttr.replace(/\\'/g, '\''),
          audioid: ''
        });
        this.dashService.reloadData();
    }

  

    applyFilter(event: KeyboardEvent): void {
        let filterValue =  (event.target as HTMLInputElement).value;
        filterValue = filterValue.trim(); 
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

    freeSearch(event: Event): void{
        this.dashService.complianceFreeSearch = this.dataSource.filter;
        //  this.dashService.reloadData();   
        (event.target as HTMLInputElement).value=''; 
    }
}
