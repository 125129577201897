import { Component, OnInit } from '@angular/core';
import {DashService} from '../../services/dash.service';
import { faFrown,faSmile,faMeh } from '@fortawesome/free-regular-svg-icons';
@Component({
    selector: 'app-dash-display-top-box',
    templateUrl: './dash-display-top-box.component.html',
    styleUrls: ['./dash-display-top-box.component.css']
})
export class DashDisplayTopBoxComponent implements OnInit {
    faFrown=faFrown;
    faSmile=faSmile;
    faMeh=faMeh;
    
    constructor(
        public dashService: DashService
    ) { }

    ngOnInit(): void {
    }

}
