import { Injectable,NgZone } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import {SelectedTranscript} from './dash.service';
import { ThrowStmt } from '@angular/compiler';

export interface TranscriptResponse{
    success: boolean;
    data: [Word [],Keyword [],History [],[],[{waveForm: string}],[{audioEvents: string}],
        [{s3Link: string}],[],Pause [],Keyword []];
}

export interface Pause {
    pauseStart: number;
    pauseFinish: number;
}
export interface Word {
    seq:             number;
    asrId:           string;
    word:            string;
    startTime:       number;
    endTime:         number;
    confidence:      null;
    speaker:         number;
    sentiment:       number;
    emotion:         string;
    id:              string;
    score:           number;
    index:           number;
    cumulativeScore: number;
    isKeyword?: boolean;
}

export interface Keyword {
    keyword: string;
    keywordCount: number;
    complianceName: string;
    isCompliant?: number;
    audioComplianceId?: number;
}

interface Comment {
    comment: string;
    dtAdded: string;
    id: string;
    sessionkey: string;
    userid: string;
    username: string;
}interface History {
    action: string;
    auditdt: string;
    userid: string;
    username: string;
}
@Injectable({
    providedIn: 'root'
})

export class TranscriptService {
    words!: Word [];
    compliances!: Keyword[];
    keywords!:Keyword[]
    history!: History [];
    comments!: Comment [];
    pauses!: Pause[];
    waveForm!: string;
    audioEvents!: string;
    transcript!: SelectedTranscript;
    wavesurfer: any = null;
    lines!: Word [][];
    currentTime = 0;

    
    constructor(
        public httpClient: HttpClient,
        public authService: AuthService,
        private ngZone: NgZone
    ) { }

    loadTranscript(transcript: SelectedTranscript): void{       
        this.transcript = transcript;
        this.pauses = [];
        this.authService.userTrack({
          action: 'load_transcript',
          value: '',
          audioid: this.transcript.audioid
        });
        if (this.transcript.mediaTypeGroup!=='Reviews') {
            this.getSignedURL(this.transcript.audiolink);
        } else {
            this.getTranscript();
        }
    }

    getSignedURL(key: string): void {
        this.httpClient.post<string>('/api/dashboard/getSignedUrl',{
            params: {
                accountid: this.authService.userSessionData.accountid,
                key: key.split('/')[5]
            }}, {            
            responseType: 'text' as 'json'
        }).subscribe((response: string)=> {
            if (response.length ===0) {
                console.info('Some thing went wrong please try again');
                return;
            } else {
                this.transcript.s3Link=response;                           
                this.getTranscript();
                return;
            }
        },(error)=>{
            debugger;
            console.error(error);
            this.getTranscript();
        });
    }

    
    getTranscript(): void{
        this.httpClient.get<TranscriptResponse>('/api/dashboard/GetTranscript', {
            params: {
                asrid: this.transcript.asrid,
                userid: this.authService.userSessionData.userid
            }
        }).subscribe((response: TranscriptResponse)=> {           
            this.words = response.data[0];
            this.keywords = response.data[1];
            this.history = response.data[2];
            this.comments = response.data[3];
            this.waveForm = response.data[4][0].waveForm;
            this.audioEvents = response.data[5][0].audioEvents;
            this.pauses = response.data[8];
            if (response.data.length>=10) {
                this.keywords = response.data[9];
                this.compliances=response.data[1];
            }
            /*  self.annotations = authservice.permission.internal? response.data[7] :[];
            self.annotations2 = prepareAnnotationList(response.data[7]);*/
            if (this.transcript.s3Link) this.wavesurfer.load(this.transcript.s3Link,JSON.parse(this.waveForm).data,'metadata');           
            // this.wavesurfer.load(this.transcript.s3Link);
            // this.wavesurfer.play();  
            this.buildTranscript(); 
            this.buildSentiment();
        });
        
    } 

    buildSentiment(): void{
        const container = document.getElementById('sentiment-container');
        if (!container) return;
    
        const width=container.offsetWidth;
        const values = Array.from(Array(width), () => ({ value: -1, count: 0 }));
        if (!this.words.length) return;
        const endTime = this.words[this.words.length - 1]['endTime'];
        
        const shift = width / endTime;
        this.words.forEach((word,index,words) => {
            const saveValue = Math.trunc(word.startTime*shift);
            const sentiment = word.sentiment;
            if (sentiment < 1 || sentiment > 5) {
                if (!index) container.style.display = 'none';
                return;
            }
            if (!values[saveValue].count) {
                values[saveValue].count = 1;
                values[saveValue].value = sentiment;
            } else {
                const startValue = values[saveValue].count * values[saveValue].value;
                values[saveValue].count= values[saveValue].count+1;
                values[saveValue].value = Math.round((startValue + sentiment) / values[saveValue].count);
            }          
        });
        const canvas = document.getElementById('sentiment-timeline')as
            HTMLCanvasElement;
     
         canvas.width = container.offsetWidth ;
        canvas.height = 60;
        const ctx = canvas?.getContext("2d");
        let lastY = 30;
        let lastX = 0;
        if (!ctx) return;
        ctx.lineWidth = 3;
        values.forEach((value,index) => {
            if (value.value === -1) return;
            if (value.value < 1 || value.value > 5) debugger;
            let y = -1;
            let strokeStyle = 'white';
            if (value.value === 1) { y = 50; strokeStyle = 'crimson'; }
            if (value.value === 2) { y = 40; strokeStyle = 'LightCoral'; }
            if (value.value === 3) { y = 30; strokeStyle = 'LightSkyBlue'; }
            if (value.value === 4) { y = 20; strokeStyle = 'PaleGreen'; }
            if (value.value === 5) { y = 10; strokeStyle = 'Chartreuse'; }     
            if (y === -1) debugger;
            ctx.beginPath();
            ctx.moveTo(lastX, lastY);
            ctx.lineTo(index, y);
            ctx.strokeStyle = strokeStyle;
            ctx.stroke();  
            lastX = index;
            lastY = y;
              ctx.closePath();
        });
       
    }

    buildTranscript(): void{
        this.lines=[[]];
        let lineLength = 0;
        let currentLine = -1;
        let currentSpeaker = -1;
        let maxLineLength = 62; 
        const transcriptWidth = document.querySelector('.mat-tab-body-content ')?.clientWidth;
        if (transcriptWidth)  {
            maxLineLength= transcriptWidth/12;
        }
        this.words.forEach(word=>{
            lineLength = lineLength + word.word.length;
            if (lineLength<maxLineLength && currentSpeaker===word.speaker) {
                this.lines[currentLine].push(word);
            } else {
                if (currentSpeaker!==word.speaker){
                    currentSpeaker=word.speaker;
                    const speaker: Word = {
                        word:'Speaker '+word.speaker+':  ',
                        startTime:-1,
                        endTime: -1,
                        seq:-1,
                        confidence: null,
                        speaker:word.speaker,
                        sentiment:-1,
                        emotion: '',
                        id: '',
                        score: -1,
                        index:  -1,
                        cumulativeScore:0,
                        asrId:''
                    };
                    currentLine++;
                    lineLength = word.word.length + 12;
                    this.lines[currentLine]=[speaker,word];
                    return;
                }
                currentLine++;
                lineLength = word.word.length;
                this.lines[currentLine]=[word];
            }
        });      
    }
}
