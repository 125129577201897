import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {AuthService} from '../../services/auth.service';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    changePassword = false;
    loginForm = true;
    forgotPassword = false;
    showRegistrationForm = false;
    showPassErr = false;
    loginFormGroup: FormGroup;

    constructor(private authService: AuthService) {
        this.loginFormGroup = new FormGroup({
            userName: new FormControl('', [Validators.required, Validators.maxLength(60)]),
            password: new FormControl('', [Validators.required, Validators.maxLength(24)]),
            remember: new FormControl(false)
        });
    }

    ngOnInit(): void {}

    loginHasError = (controlName: string, errorName: string): boolean =>
        this.loginFormGroup.controls[controlName].hasError(errorName);

    displayBeta(): boolean  {
        const x = window.location.href.indexOf('www.lucid');
        return x === -1;
    }

    login(): void {
        this.authService.login({userName:this.loginFormGroup.value.userName, password:this.loginFormGroup.value.password});
    }
}
