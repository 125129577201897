import {
    OnInit,
    OnChanges,
    Directive,
    Input,
    HostBinding,
    Renderer2,
    ElementRef,
    SimpleChanges
} from '@angular/core';
  
function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}
  
@Directive({
    selector: '[loadSpinner]'
})
export class SpinnerDirective implements OnInit, OnChanges {
    @HostBinding('style.position')
    hostPosition = 'relative';
  
    @Input() loadSpinner;
  
    uid: string='loading-container-' + uuidv4();
  
    constructor(private targetEl: ElementRef, private renderer: Renderer2) {}
  
    ngOnInit() {
        this.uid = 'loading-container-' + uuidv4();
  
        const loadingContainer = this.renderer.createElement('div');
        this.renderer.setStyle(
            loadingContainer,
            'display',
            this.loadSpinner ? 'flex' : 'none'
        );
        this.renderer.setStyle(loadingContainer, 'justify-content', 'center');
        this.renderer.setStyle(loadingContainer, 'align-items', 'center');
        this.renderer.addClass(loadingContainer, this.uid);
        this.renderer.setStyle(loadingContainer, 'position', 'absolute');
        this.renderer.setStyle(loadingContainer, 'top', '20%');
        this.renderer.setStyle(loadingContainer, 'left', '45%');
        this.renderer.setStyle(loadingContainer, 'background', 'transparent');
        this.renderer.setStyle(loadingContainer, 'width', '80px');
        this.renderer.setStyle(loadingContainer, 'height', '40px');
        
        // custom spinner -- start
        const spinnerContainer = this.renderer.createElement('div');
        this.renderer.addClass(spinnerContainer, 'spinner-container-directive');
        const spinnerInnerDiv1 = this.renderer.createElement('div');
        const spinnerInnerDiv2 = this.renderer.createElement('div');
        const spinnerInnerDiv3 = this.renderer.createElement('div');
  
        this.renderer.appendChild(spinnerContainer, spinnerInnerDiv1);
        this.renderer.appendChild(spinnerContainer, spinnerInnerDiv2);
        this.renderer.appendChild(spinnerContainer, spinnerInnerDiv3);
  
        this.renderer.appendChild(loadingContainer, spinnerContainer);
        // custom spinner -- end
  
        this.renderer.appendChild(this.targetEl.nativeElement, loadingContainer);        
    }
  
    ngOnChanges(simpleChanges: SimpleChanges) {
        if (simpleChanges.loadSpinner) {
            const container = this.targetEl.nativeElement;
            const div = container.querySelector('.' + this.uid);
            if (div) {
                this.renderer.setStyle(
                    div,
                    'display',
                    this.loadSpinner ? 'flex' : 'none'
                );
            }
        }
    }
}
  
