import { Component, OnInit } from '@angular/core';
import {DashService} from '../../services/dash.service';

@Component({
    selector: 'app-dash-display-categories',
    templateUrl: './dash-display-categories.component.html',
    styleUrls: ['./dash-display-categories.component.css']
})
export class DashDisplayCategoriesComponent implements OnInit {

    constructor(public dashService: DashService) { }

    ngOnInit(): void {
    }



    deselectCategory(category: string,value: string): void{
        this.dashService.currentCategoryValues[category].values =
            this.dashService.currentCategoryValues[category].values.filter((title)=> value!==title);        
        this.dashService.reloadData();    
    }
}
