import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {HttpClient} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import { DashService } from 'src/app/services/dash.service';
import {FormControl} from '@angular/forms';

type Group = {
    operator: string;
    statements: Statement[];
    groups: Group[];
};

type Statement ={
    operator?: string;
    name: string;
    condition: Condition;
    subcondition: {name: string};
    value: string;
    word1: string;
    word2: string;
    numWords: number;
    preserve: boolean;
};

type Condition = {
    name: string;
    subConditions?: [
        { name: string}
    ];
};
@Component({
    selector: 'app-query-builder',
    templateUrl: './query-builder.component.html',
    styleUrls: ['./query-builder.component.css']
})


export class BuildQueryComponent implements OnInit {
    scriptName='';
    scriptText='';
    querySaveForm = new FormControl();
    querySaveNames: string[] = ['One', 'Two', 'Three'];
    querySaveName='';

    constructor(
        private authService: AuthService,
        private httpClient: HttpClient,
        private snackbar: MatSnackBar,
        public dashService: DashService
      
    ) { 
        if (this.dashService.advancedQuery?.sql.length>1) {
            this.groups = this.dashService.advancedQuery.json as Group[];
            this.parseQuery();
        }
    }


    ngOnInit(): void {
        this.getScriptComplianceQueryList();
     
    }

 
    groups: Group[] = [{
        operator:'',
        statements:[],
        groups:[]
    }
    ];

   
    operators = [      
        {name: 'OR'},
        {name: 'AND'},
        {name: 'AND NOT'},
        {name: 'OR NOT'}
    ];

    fields = [
        {name: 'resulttextcombined'},
        {name: 'resulttext'},
        {name: 'resulttext2'}
    ];
     
    conditions = [
        {name: 'CONTAINS',
            subConditions: [
                {name: 'WORD'},
                {name: 'NEAR'},
                {name: 'FORMSOF'}
            ]
        },
        {name: 'FREETEXT'},
        {name: 'DURATION',
            subConditions: [
                {name: '<'},
                {name: '>'},
                {name: '='}
            ]
        }
    ];

    addStatement(group: Group, groupIndex: number): void{
        group.statements.push({
            name:'',
            condition:{name:''},
            subcondition:{name:''},
            value:'',
            word1:'',
            word2:'',
            numWords:30,
            preserve:false,
            operator:group.statements.length?'OR':''
        });
    }

    deleteStatement(groupIndex: number,index: number): void{
        this.groups[groupIndex].statements.splice(index,1);
        this.parseQuery();
    }

    addGroup(group: Group): void{
        group.groups.push({
            operator:'AND',
            statements:[],
            groups:[]
        });
    }

    deleteGroup(group: Group,groupIndex: number,parentGroup: Group): void{
        parentGroup.groups.splice(groupIndex,1);
        this.parseQuery();
    }

    checkCondition(condition, condition2): boolean {
        return condition.name===condition2.name;
    }

    checkSubCondition(condition, condition2): boolean {
        return condition.name===condition2.name;
    }

    querySQL = '';

    parseGroup(group: Group,groupIndex: number,topGroup = false): string{
        let querySQL = '';
        if (!topGroup) querySQL+=' '+group.operator+' ';
        querySQL+='(';
   
        group.statements.forEach((statement,index)=>{
            if (statement.condition.name==='CONTAINS' && 
                    statement.subcondition.name==='WORD'){
                if (index) querySQL+=' '+statement.operator+' ';
                querySQL+=`CONTAINS(${statement.name}, '"${statement.value}"')`;
            }
            if (statement.condition.name==='CONTAINS' && 
            statement.subcondition.name==='NEAR'){
                if (index) querySQL+=' '+statement.operator+' ';
                querySQL+=`CONTAINS(${statement.name}, 'NEAR(( ${statement.word1}, ${statement.word2}), 
                ${statement.numWords}, ${statement.preserve})')`;
            }
            if (statement.condition.name==='CONTAINS' && 
            statement.subcondition.name==='FORMSOF'){
                if (index) querySQL+=' '+statement.operator+' ';
                querySQL+=`CONTAINS(${statement.name}, 'FORMSOF(INFLECTIONAL, ${statement.value})')`;
            }
            if (statement.condition.name==='FREETEXT' ){
                if (index) querySQL+=' '+statement.operator+' ';
                querySQL+=`FREETEXT(${statement.name}, '"${statement.value}"')`;
            }
            if (statement.condition.name==='DURATION' ){
                if (index) querySQL+=' '+statement.operator+' ';
                querySQL+=`DURATION ${statement.subcondition.name} ${statement.value}`;
            }
        });
        group.groups.forEach((subGroup,index)=>{
            querySQL+=this.parseGroup(subGroup,index);
        });
        querySQL+=')';
        return querySQL;
    }

    parseQuery(): string {
        if (!this.groups[0].statements.length) return '';
        let querySQL='';
        this.groups.forEach((group,groupIndex)=>{
            querySQL += this.parseGroup(group,groupIndex,true);
        });
        if (querySQL.length<3) {
            querySQL = '';
        }
        this.querySQL = querySQL;
        return querySQL;
    }

    applySQL(): void {
        // this.parseQuery();
        this.dashService.advancedQuery.sql=this.querySQL;
        this.dashService.advancedQuery.json=this.groups;
        this.dashService.reloadData();
       
    }

    clearSQL(): void {
        this.dashService.advancedQuery.sql='';
        // this.dashService.advancedQuery.json=[];
        this.dashService.reloadData();      
    }

    resetSQL(): void {
        this.dashService.advancedQuery.json=[];
        this.groups=[{
            operator:'',
            statements:[],
            groups:[]
        }
        ];
    }

    getScriptComplianceQueryList(): void{
        this.httpClient.get<{data: {queryName: string}[]}>('/api/adminCompliance/getScriptComplianceQueryList',{
            params:{accountid:this.authService.userSessionData.accountid}
        }).subscribe((response: {data: {queryName: string}[]}) =>{
            this.querySaveNames=[];
            response.data.forEach(element => {
                this.querySaveNames.push(element.queryName);
            });
        });
    }

    loadSQL(): void {
        this.httpClient.get<{data: {queryObj: string;query: string}[]}>('/api/adminCompliance/getScriptComplianceQuery',{
            params:{accountid:this.authService.userSessionData.accountid,
                queryName:this.querySaveName}
        }).subscribe((response) =>{
            this.dashService.advancedQuery.sql=response.data[0].query;  
            this.groups = JSON.parse(response.data[0].queryObj);  
            this.dashService.reloadData();
            // this.dashService.advancedQuery.json=this.groups;              
        });
    }
    saveSQL(): void {
        this.parseQuery();
        this.dashService.advancedQuery.sql=this.querySQL;
        this.dashService.advancedQuery.json=this.groups.length?this.groups:[];
     
        this.httpClient.post('/api/adminCompliance/saveScriptComplianceQuery',{
            accountid:this.authService.userSessionData.accountid,
            queryname:this.querySaveName,
            query:this.dashService.advancedQuery.sql,
            queryObj:JSON.stringify(this.dashService.advancedQuery.json)
        
        }).subscribe(response =>{
            if (response['success']) {
                this.snackbar.open('Success Saving Script','Dismiss', {               
                    verticalPosition: 'top', 
                    horizontalPosition: 'right',
                    duration: 2000,
                    panelClass:'success'
                });                                  
            } else{
                this.snackbar.open('Error Saving Script','Dismiss', {               
                    verticalPosition: 'top', 
                    horizontalPosition: 'right',
                    duration: 2000,
                    panelClass:'error'
                });  
            }
        });
    }

}
