import { Component, OnInit,Inject } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {HttpClient} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
@Component({
    selector: 'app-add-script-compliance-window',
    templateUrl: './add-script-compliance-window.component.html',
    styleUrls: ['./add-script-compliance-window.component.css']
})
export class AddScriptComplianceWindowComponent implements OnInit {
    complianceName='';
    complianceText='';
    complianceText2='';
    selectionAttr='';
    gauges='';
    selectionAttrVal='';
    seq=null;
    complianceHint='';
    decisionThreshold='';

    constructor(
        private authService: AuthService,
        private httpClient: HttpClient,
        private snackbar: MatSnackBar,
        private dialogRef: MatDialogRef<AddScriptComplianceWindowComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void {
    }

    saveScriptCompliance(): void { 
        this.complianceName=this.complianceName.replace(' ','').replace('-','');
        if (!this.complianceName.length || !this.complianceText.length ){
            this.snackbar.open('Error - Name and Text required','Dismiss', {               
                verticalPosition: 'top', 
                horizontalPosition: 'right',
                duration: 2000,
                panelClass:'error'
            });  
            return;
        }
        this.httpClient.post('/api/adminCompliance/addScriptCompliance',{
            seq:this.seq,
            complianceName:this.complianceName,
            complianceText:this.complianceText,
            complianceText2:this.complianceText2,
            gauges:this.gauges,
            complianceHint:this.complianceHint,
            scriptName:this.data.scriptName,
            selectionAttr:this.selectionAttr,
            selectionAttrVal:this.selectionAttrVal,
            decisionThreshold:this.decisionThreshold,
            scriptId:this.data.scriptId
        
        }).subscribe(response =>{
            if (response['success']) {
                this.snackbar.open('Success Adding Script','Dismiss', {               
                    verticalPosition: 'top', 
                    horizontalPosition: 'right',
                    duration: 2000,
                    panelClass:'success'
                });                
                this.dialogRef.close(true);     
            } else{
                this.snackbar.open('Error Adding Script','Dismiss', {               
                    verticalPosition: 'top', 
                    horizontalPosition: 'right',
                    duration: 2000,
                    panelClass:'error'
                });  
            }
        });
    }
    
}
