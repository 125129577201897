<div fxLayout="column" class="main-trend" *ngIf="dataLoaded">

    <div fxLayout="row" style="background-color:black;height:40px">
        <div fxFlex="2%"></div>
        <div fxFlex="92%" style="margin-top:8px;color:white">TREND ANALYSIS</div>
        <div fxFlex="5%">
            <button style="margin-top: -3px;" mat-button class="close-icon" [mat-dialog-close]="true">
                <mat-icon class="close-window">cancel</mat-icon>
            </button>
        </div>
    </div>
    <highcharts-chart [Highcharts]="highcharts" [options]="chartOptions" [(update)]="updateFlag" style="width: 100%; height: 400px; display: block;">
    </highcharts-chart>
</div>