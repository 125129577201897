import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import { ThrowStmt } from '@angular/compiler';
import { ParamMap, Router, ActivatedRoute } from '@angular/router';
import {getGuid} from '../services/utility.service';

export interface CurrentKeywordValues{
    keyword: string;
    operator: string;
    stereo: string;   
}
export interface SelectedTranscript {
    rownum:                  string;
    accountid:               string;
    audioid:                 string;
    asrid:                   string;
    duration:                number;
    filename:                string;
    filedate:                string;
    audiolink:               string;
    status:                  string;
    uniqueKeywords:          number;
    selectedKeywordMentions: number;
    impactScore:             number;
    userRating:              null;
    channels:                number;
    comments:                string [];
    sentiment:               null;
    mediaType:               number;
    ivrTime:                 number;
    holdTime:                number;
    agentTime:               number;
    mediaTypeGroup:          string;
    mediaTypeLabel:          string;
    wand:                    number;
    s3Link?: string; 
}
export interface TransferPayload {
    currentCategoryValues: { [key: string]: CurrentCategoryValue };
    currentKeywordValue:   CurrentKeywordValue;
    fileName:              null;
    selectedPhrase:        null;
    searchComment:         null;
    reportEndDate:         string;
    reportStartDate:       string;
    justCommentValue:      boolean;
    selectedCategory:      string;
    sortDir:               string;
    sortColumn:            string;
    agent:                 string;
    sFilter:               number;
    selectedTranscript: SelectedTranscript;
}

export interface CurrentCategoryValue {
    category: string;
    title:    string;
    values:   any[];
    color:    string;
}

export interface CurrentKeywordValue {
    value:    string;
    selected: null;
    values:   CurrentKeywordValues [];
}

@Injectable({
    providedIn: 'root'
})

export class TransferService {
   

    constructor(
        public httpClient: HttpClient,
        public authService: AuthService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    checkTranscriptURL(): Promise <any>{
        return new Promise( (resolve)=> {
            const transcriptGUID=this.route.snapshot.queryParamMap.get('transcriptGUID');
            if (transcriptGUID) {
                this.processTranscriptTransfer(transcriptGUID,resolve);
                return;
            }
            resolve(null);
        });    
    }

    processTranscriptTransfer(transcriptGUID: string,resolve): void{
        this.router.navigate(
            [], 
            {
                relativeTo: this.route,
                queryParams: {transcriptGUID:null },
                queryParamsHandling: 'merge'
            });
        const options: Record<string,any> ={
            params: {
                cacheguid:transcriptGUID,
                enddate:'2100-01-01',
                endrow:'99999',
                startdate: '1900-01-01',
                startrow: '0',
                categorizationFilterJson: '[]',
                userid:this.authService.userSessionData.userid,
                filename:transcriptGUID,
                mode: 'single',
                sfilter: '0',
                sortcol: 'rownum',
                sortdir: 'ASC'
            }
        };
        this.httpClient.post<{data: [];
            success: boolean;
            totals: [];
            category: [];
            keywords: [];
            mediaType: {mediaTypeGroup: string} [];
            trends:  [];
            compliances: [];
        }>('/api/dashboard/AccountGetStats2', options).subscribe((response)=>{           
            resolve(response.data.pop() );
        });
    }
    checkTransferURL(): Promise<TransferPayload|null> {
        return new Promise( (resolve)=> {
            const transferGUID=this.route.snapshot.queryParamMap.get('transferGUID');
            if (transferGUID) {
                this.processTransfer(transferGUID,resolve);
                return;
            }
            resolve(null);
        });    
    }

    processTransfer(transferGUID: string,resolve: (state: TransferPayload) => void): void{
        this.router.navigate(
            [], 
            {
                relativeTo: this.route,
                queryParams: {transferGUID:null },
                queryParamsHandling: 'merge'
            });
        this.httpClient.get<{data: {payload: string}}>('/api/dashboard/getTinyLink', {
            params: {
                guid:transferGUID
            }
        }).subscribe((response: {data: {payload: string}})=>{
            const state: TransferPayload= JSON.parse(response.data.payload);
            resolve(state);
        });
    }

    saveTinyLink(payload: string): string{
        const guid = getGuid();
        this.httpClient.get('/api/dashboard/saveTinyLink', {
            params: {
                guid: guid,
                payload: payload,
                accountId: this.authService.userSessionData.accountid
            }
        }).subscribe((response)=>{

        });
        return guid;
    }
    
}

