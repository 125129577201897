<div class="container" [loadSpinner]="!dashService.complianceLoaded">
    <div fxLayout="row" class="example-header flx">
        <h2 fxFlex="auto" class="text-lucid">Compliance</h2>
        <div fxFlex="20%">
        </div>
        <mat-form-field appearance="fill" fxFlex="30%">
            <mat-label>Search</mat-label>
            <input matInput (keydown.enter)="freeSearch($event)" (keyup)="applyFilter($event)">
        </mat-form-field>
        <div fxFlex="10%">
            <button style="float: right" matTooltip="Export Data" color="primary" matTooltipPosition="above" mat-mini
                (click)="exporter.exportTable('csv',{fileName:'compliances'})">
                <mat-icon>file_download</mat-icon>
            </button>
        </div>

    </div>

    <div fxLayout="row" style="overflow:auto;height:345px">
        <table matTableExporter [hiddenColumns]="[3]" #exporter="matTableExporter" mat-table matSort [dataSource]="dataSource"
            class="mat-elevation-z8 category-table">
            <ng-container matColumnDef="complianceName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td [matTooltip]="complianceService.complianceHints[element.complianceName.split('(')[0].trim()]" matTooltipShowDelay="1000"
                    matTooltipPosition="above" mat-cell *matCellDef="let element"> {{element.complianceName}} </td>
            </ng-container>

            <ng-container matColumnDef="complianceOpportunities">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Compliance Opportunity </th>
                <td mat-cell *matCellDef="let element"> {{element.complianceOpportunities}} </td>
            </ng-container>

            <ng-container matColumnDef="complied">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Complied </th>
                <td mat-cell *matCellDef="let element"> {{element.complied}} </td>
            </ng-container>

            <ng-container matColumnDef="compliancePercent">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Compliance Percent </th>
                <td mat-cell *matCellDef="let element"> {{(element.compliancePercent*100).toFixed(2)}} </td>
            </ng-container>

            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <div>Select</div>

                </th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon (click)="selectCompliance(element, 1)" style="cursor: pointer;color: #3a8fc8;">thumb_up_alt</mat-icon>
                    <mat-icon (click)="selectCompliance(element, 0)" style="cursor: pointer;color: #3a8fc8;">view_day</mat-icon>
                    <mat-icon (click)="selectCompliance(element, -1)" style="cursor: pointer;color: #3a8fc8;">thumb_down</mat-icon>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 20,1000]" showFirstLastButtons></mat-paginator>
</div>