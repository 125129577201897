import { BrowserModule } from '@angular/platform-browser';
import {CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './page/dashboard/dashboard.component';
import {TrendsComponent} from './page/trends/trends.component';
import { LoginComponent } from './page/login/login.component';
import { TopmenuComponent } from './layout/topmenu/topmenu.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {MaterialModule} from './commonModule/material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {ReactiveFormsModule,FormsModule} from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { AgGridModule } from '@ag-grid-community/angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './token.interceptor';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material-dayjs';
import { DashDatePickerComponent } from './components/dash-date-picker/dash-date-picker.component';
import { DashDisplayCategoriesComponent } from './components/dash-display-categories/dash-display-categories.component';
import { DashDisplayCategoryTableComponent } from './components/dash-display-category-table/dash-display-category-table.component';
import { DashDisplayKeywordTableComponent } from './components/dash-display-keyword-table/dash-display-keyword-table.component';
import { DashDisplayComplianceTableComponent } from './components/dash-display-compliance-table/dash-display-compliance-table.component';
import {DashDisplayKeywordsComponent} from './components/dash-display-keywords/dash-display-keywords.component';
import {DashDisplayCompliancesComponent} from './components/dash-display-compliances/dash-display-compliances.component';
import { TranscriptWindowComponent } from './dialogs/transcript-window/transcript-window.component';
import { TrendWindowComponent } from './dialogs/trend-window/trend-window.component';
import { DashDisplayTopBoxComponent } from './components/dash-display-top-box/dash-display-top-box.component';
import { DashDisplayTopBoxComplianceComponent } from './components/dash-display-top-box-compliance/dash-display-top-box-compliance.component';
import { DashDisplayTopBoxComplianceTotalsComponent } from './components/dash-display-top-box-compliance-totals/dash-display-top-box-compliance-totals.component';
import { DashDisplayComplianceGaugeComponent } from './components/dash-display-compliance-gauge/dash-display-compliance-gauge.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AdminScriptComplianceComponent } from './page/admin-script-compliance/admin-script-compliance.component';
import { AddScriptWindowComponent } from './dialogs/add-script-window/add-script-window.component';
import { AddScriptComplianceWindowComponent } from './dialogs/add-script-compliance-window/add-script-compliance-window.component';
import { ConfirmBoxComponent } from './dialogs/confirm-box/confirm-box.component';
import { FilenameBoxComponent } from './dialogs/filename-box/filename-box.component';
import { CopylinkBoxComponent } from './dialogs/copylink-box/copylink-box.component';
import { FormatScriptBoxComponent } from './dialogs/formatScript-box/formatScript-box.component';
import { PrettyjsonPipe } from './pipes/prettyjson.pipe';
import {BuildQueryComponent } from './dialogs/query-builder/query-builder.component';
import { QueryBuilderModule } from 'angular2-query-builder';
import {SpinnerDirective} from './directives/spinner.directive';
import {CloudServerComponent} from './page/cloud-server/cloud-server.component';
@NgModule({

    declarations: [
        AppComponent,
        DashboardComponent,
        TrendsComponent,
        LoginComponent,
        TopmenuComponent,
        CloudServerComponent,
        DashDatePickerComponent,
        DashDisplayCategoriesComponent,
        DashDisplayCategoryTableComponent,
        DashDisplayKeywordTableComponent,
        DashDisplayComplianceTableComponent,
        DashDisplayKeywordsComponent,
        DashDisplayCompliancesComponent,
        TranscriptWindowComponent,
        TrendWindowComponent,
        DashDisplayTopBoxComponent,
        DashDisplayTopBoxComplianceComponent,
        DashDisplayTopBoxComplianceTotalsComponent,
        DashDisplayComplianceGaugeComponent,
        AdminScriptComplianceComponent,
        AddScriptWindowComponent,
        AddScriptComplianceWindowComponent,
        ConfirmBoxComponent,
        FilenameBoxComponent,
        CopylinkBoxComponent,
        FormatScriptBoxComponent,
        PrettyjsonPipe,
        BuildQueryComponent,
        SpinnerDirective
    ],
    imports: [
        BrowserModule,
        FontAwesomeModule,
        AppRoutingModule,
        MaterialModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FormsModule,
        FlexLayoutModule,
        HttpClientModule,
        HighchartsChartModule,
        NgxSpinnerModule,
        CommonModule,
        QueryBuilderModule,
        AgGridModule.withComponents([]),
        NgxDaterangepickerMd.forRoot()
    ],
    providers: [
        { provide: APP_BASE_HREF, useValue: '/' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        TranscriptWindowComponent,
        TrendWindowComponent
    ]
})
export class AppModule { }
