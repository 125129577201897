import { Component, OnInit,ViewChild } from '@angular/core';
import {DashService,KeywordList} from '../../services/dash.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-dash-display-keyword-table',
    templateUrl: './dash-display-keyword-table.component.html',
    styleUrls: ['./dash-display-keyword-table.component.css']
})


export class DashDisplayKeywordTableComponent implements OnInit {
    displayedColumns: string[] = ['keyword', 'calls', 'mentions', 'calls2','mentions2','select'];   
    categoryHeading ='';
    dataSource = new MatTableDataSource<KeywordList>();
    

    constructor(public dashService: DashService,
    public authService: AuthService) { }
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;
    
   
    ngAfterViewInit(): void {

        this.dataSource.paginator = this.paginator;        
        this.dataSource.sort = this.sort;
    }

    ngOnInit(): void {
        if (this.dashService.stereoConfig().isTwoChannel!==1) {
            this.displayedColumns= ['keyword', 'callsBoth', 'mentionsBoth','select'];   
        }
        this.dashService.keywordListUpdate.subscribe(keywords => { 
            if (this.dashService.freeKeywordList?.length>0) return;                                      
            this.dataSource.data = keywords;           
        });     
    }

    selectKeywordRoleChange(): void{
        if (this.dashService.stereoConfig().isTwoChannel!==1 || this.dashService.selectKeywordRole==='Both') {
            this.displayedColumns= ['keyword', 'callsBoth', 'mentionsBoth','select'];   
        } else {
            this.displayedColumns=  ['keyword', 'calls', 'mentions', 'calls2','mentions2','select'];   
        }
    }

    getFreeSearchResults(event: Event): void {            
        if ((event.target as HTMLInputElement).value.length===0) {
            this.dashService.freeKeywordList=[];
            this.dashService.reloadData();
            return;
        }
        this.dashService.getFreeSearchResults( (event.target as HTMLInputElement).value).then(results=>{
            this.dataSource.data = this.dashService.freeKeywordList;
        });              
    }

    selectKeyword(keywordList: KeywordList,selection: boolean): void{      
        const keywordSelection = {
            keyword: keywordList.keyword.replace(/\\'/g, '\''),
            operator: selection?'':'NOT',
            stereo:this.dashService.stereoConfig().isTwoChannel ? this.dashService.selectKeywordRole : ''
        };
        if (this.dashService.currentKeywordValues)
            this.dashService.currentKeywordValues.push(keywordSelection);
        else
            this.dashService.currentKeywordValues = [keywordSelection];
         this.authService.userTrack({
          action: 'select_keyword',
          value: keywordList.keyword.replace(/\\'/g, '\''),
          audioid: ''
        });
        this.dashService.reloadData();
    }

  

    applyFilter(event: KeyboardEvent): void {
        let filterValue =  (event.target as HTMLInputElement).value;
        filterValue = filterValue.trim(); 
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

}
