import { Component, OnInit ,Inject} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-filename-box',
    templateUrl: './filename-box.component.html',
    styleUrls: ['./filename-box.component.css']
})
export class FilenameBoxComponent implements OnInit {
    filename='';

    constructor(public dialogRef: MatDialogRef<FilenameBoxComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit(): void {
    }

}
