<div class="category_selection">

    <span class="category" *ngFor="let category of dashService.currentCategoryValues| keyvalue">
        <span [style.background-color]="'#'+category.value.color" *ngIf="category.value.values.length==0" class="tag label label-info">All
            {{category.value.title}}</span>
        <span style="font-size:11px;display: inline-block;line-height: 1.6;margin: 2px" class="tag label label-info" *ngFor="let value of category.value.values"
            [style.background-color]="'#'+category.value.color">
            {{value}}
            <mat-icon (click)="deselectCategory(category.value.category,value)" style="margin-left:4px;width:9px;height: 3px;font-size: 13px;">
                disabled_by_default</mat-icon>
        </span>

    </span>


</div>