
import { Component, OnInit } from '@angular/core';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import {AuthService} from '../../services/auth.service';
import {DashService} from '../../services/dash.service';

@Component({
    selector: 'app-topmenu',
    templateUrl: './topmenu.component.html',
    styleUrls: ['./topmenu.component.css']
})
export class TopmenuComponent implements OnInit {
    faLanguage=faLanguage;
   

    constructor(public dashService: DashService,
        public authService: AuthService) { 

    }

    ngOnInit(): void {
       
    }

}
