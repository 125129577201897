<div class="page-header-inner ng-scope">
    <div class="page-header">
        <div class="page-header-top">
            <div fxLayout="row" class="container">
                <div fxFlex="15%"></div>
                <div fxFlex="20%" class="page-logo">
                    <a ng-click="reload()">
                        <img src="assets/images/logo.png" class="logo-default">
                    </a>
                </div>
                <div fxFlex="45%"></div>
                <div fxFlex="20%" class="top-menu" *ngIf="authService.isAuthenticated()">
                    <button mat-icon-button style="color:rgb(142, 163, 182)" [matMenuTriggerFor]="menu">
                        <img alt="" class="img-circle" src="assets/images/avatar.png">
                        <mat-icon>more_vert</mat-icon>
                        {{authService.userSessionData.name}}
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item routerLink="/">
                            <mat-icon>dialpad</mat-icon>
                            <span>Dashboard</span>
                        </button>
                        <button *ngIf="authService.permission.ShowCompliance==='true' && authService.permission.internal==='true'" mat-menu-item
                            routerLink="adminScriptCompliance">
                            <mat-icon>perm_phone_msg</mat-icon>
                            <span>Admin Script Compliance</span>
                        </button>
                        <button *ngIf="authService.userSessionData.userid==='9'" mat-menu-item routerLink="cloudServers">
                            <mat-icon>perm_phone_msg</mat-icon>
                            <span>Cloud Server</span>
                        </button>
                        <button mat-menu-item (click)="authService.logout()">
                            <mat-icon>logout</mat-icon>
                            <span>Logout</span>
                        </button>
                    </mat-menu>

                </div>
            </div>
        </div>
    </div>