<div class="keyword_selection">
    <span class="keyword" *ngFor="let keyword of dashService.currentKeywordValues">
        <span style="background-color:purple;font-size:11px;display: inline-block;line-height: 1.6;margin: 2px" class="tag label label-info">
            {{keyword.operator}} &nbsp; {{keyword.stereo.length?keyword.stereo+' - ':''}}{{keyword.keyword}}
            <mat-icon (click)=removeKeyword(keyword) style="margin-left:4px;width:9px;height: 3px;font-size: 13px;">
                disabled_by_default</mat-icon>
        </span>
    </span>
    <span class="keyword" *ngIf="!dashService.currentKeywordValues || dashService.currentKeywordValues.length===0">
        <span style="background-color:purple;font-size:11px;display: inline-block;line-height: 1.6;margin: 2px" class="tag label label-info">
            All Keywords
        </span>
    </span>

</div>