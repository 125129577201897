import { Component, OnInit } from '@angular/core';
import {DashService} from '../../services/dash.service';

@Component({
    selector: 'app-dash-display-top-box-compliance',
    templateUrl: './dash-display-top-box-compliance.component.html',
    styleUrls: ['./dash-display-top-box-compliance.component.css']
})
export class DashDisplayTopBoxComplianceComponent implements OnInit {
   
    
    constructor(
        public dashService: DashService
    ) { }

    ngOnInit(): void {
    }

}
