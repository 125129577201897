<div class="compliance_selection">
    <span class="compliance" *ngFor="let compliance of dashService.currentComplianceValues">
        <span style="background-color:purple;font-size:11px;display: inline-block;line-height: 1.6;margin: 2px" class="tag label label-info">
            {{compliance.operator}} &nbsp; {{compliance.stereo.length?compliance.stereo+' - ':''}}{{compliance.compliance}}
            <mat-icon (click)=removeCompliance(compliance) style="margin-left:4px;width:9px;height: 3px;font-size: 13px;">
                disabled_by_default</mat-icon>
        </span>
    </span>
    <span class="compliance" *ngIf="!dashService.currentComplianceValues || dashService.currentComplianceValues.length===0">
        <span style="background-color:purple;font-size:11px;display: inline-block;line-height: 1.6;margin: 2px" class="tag label label-info">
            All Compliances
        </span>
    </span>

</div>