import { Component, OnInit, HostListener ,ViewChild} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import { HttpClient } from '@angular/common/http';
import {DashService} from '../../services/dash.service';
import {MatDialog} from '@angular/material/dialog';
import {ServerSideStoreType } from '@ag-grid-community/core';

import {FilenameBoxComponent} from '../../dialogs/filename-box/filename-box.component';
import {CopylinkBoxComponent} from '../../dialogs/copylink-box/copylink-box.component';
import {BuildQueryComponent} from '../../dialogs/query-builder/query-builder.component';
import { TransferService } from 'src/app/services/transfer.service';
import * as dayjs from 'dayjs';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
    // eslint-disable-next-line no-undef
    @ViewChild('copyLink') public copyLinkRef;
    debounce=0;
    showQueryBuilder = false;

    @HostListener('window:resize', ['$event'])
    onResize(event: any): void {
        if (this.debounce) {           
            window.clearTimeout(this.debounce);
            this.debounce=0;
        }
        this.debounce=window.setTimeout(()=>{
            this.dashService.resizeGrid();
        },100);        
    }  
    constructor(
        private authService: AuthService,
        private httpClient: HttpClient,
        public dashService: DashService,
        private matDialog: MatDialog,
        private transferService: TransferService
    ) { }

    ngOnInit(): void {
        this.authService.reConnect();
        this.authService.userTrack({
          action: 'load_dash',
          value: '',
          audioid: ''
        });
    }

    openQueryBuilder(): void {
        this.showQueryBuilder=!  this.showQueryBuilder;
        if (this.showQueryBuilder) window.scrollTo(0,0);
    }

    exportGrid(): void {
        this.dashService.gridOptions.serverSideStoreType = ServerSideStoreType.Full;
        this.dashService.reloadData();
        this.matDialog.open(FilenameBoxComponent, {
            height: '30%',
            width: '20%',           
            panelClass: 'filename-container',
            data:{title:'Export CSV Filename',filename:'Filename'}
        }).afterClosed().subscribe(result => {
            const options={
                fileName:result,
                columnKeys:['rownum','filedate','filename','duration','mediaTypeLabel',
                    'uniqueKeywords','selectedKeywordMentions','ivrTime','holdTime','agentTime','status',
                    'comments','GUID'
                ],              
                processCellCallback:(params): string=>{
                    if (params.column.getColId()==='duration'){
                        return this.dashService.formatTime(params.node.data.duration);
                    }
                    if (params.column.getColId()==='filedate'){
                        const date = new Date(params.node.data.filedate);
                        return date.toLocaleString();
                    }
                    if (params.column.getColId()==='GUID'){
                        const audioLink = params.node.data.audiolink.split('/').pop().split('.wav').shift();                   
                        return window.location.href+'?transcriptGUID='+audioLink;                       
                    }
                    return params.value;
                }
            };

            if (result) {         
                this.dashService.columnDefs.forEach(field => {
                    if (field?.field?.indexOf('opt') === 0) {
                        options.columnKeys.push(field?.field);
                    }; 
                });
                this.dashService.grid?.api?.exportDataAsCsv(options);
            }
            this.dashService.gridOptions.serverSideStoreType = ServerSideStoreType.Partial;
        });       
    }

    saveTinyLink(): void{
        const transferState = {
            currentCategoryValues:this.dashService.currentCategoryValues, 
            currentKeywordValue:{values:this.dashService.currentKeywordValues},
            currentComplianceValues:{values:this.dashService.currentComplianceValues},
            fileName :this.dashService.fileName,
            selectedPhrase :null,
            searchComment :this.dashService.commentField,
            advancedQeury :null,
            reportEndDate:this.dashService.selectedDate.endDate.toDate(),
            reportStartDate :this.dashService.selectedDate.startDate.toDate(),
            justCommentValue :this.dashService.comment,
            selectedCategory :this.dashService.selectedCategory,
            sortDir  :this.dashService.sortDir,
            sortColumn  :this.dashService.sortColumn,
            agent :navigator.userAgent,
            sFilter :this.dashService.sFilter
        };

        const transferStateString = JSON.stringify(transferState);
        const transferGuid =  this.transferService.saveTinyLink(transferStateString);
        this.matDialog.open(CopylinkBoxComponent,{           
            width: '600px',
            backdropClass: 'cdk-overlay-transparent-backdrop',
            hasBackdrop: true,
            data: {transferGuid, position: this.copyLinkRef,saveAsNameOption:true,transferStateString}
        });
    }
    
    displaySelectedCategories(): boolean {
        let selected = false;
        Object.entries(this.dashService.currentCategoryValues).forEach(category=>{
            if (category[1].values.length>0) selected = true;
        });
        return selected; 
    }

    displaySelectedKeywords(): boolean {
        if (!this.dashService.currentKeywordValues) return false;
        if (this.dashService.currentKeywordValues.length>0) return true;
        return false; 
    }

    displaySelectedCompliances(): boolean {
        if (!this.dashService.currentComplianceValues) return false;
        if (this.dashService.currentComplianceValues.length>0) return true;
        return false;
    }
}
