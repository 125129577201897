import { Component } from '@angular/core';
import {DashService} from './services/dash.service';
import * as LogRocket from 'logrocket';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'Eagle2';
    constructor( 
        public dashService: DashService
    ) {       
        if (window.location.hostname !== 'localhost') {  
            // LogRocket.init('oltvbi/eagle2');
        }
    }
}
