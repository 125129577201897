import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Router,NavigationEnd} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import { filter } from 'rxjs/operators';
export interface UserResponse {
    success: boolean;
    token:   string;
    user:    UsersResponse;
}


export interface UsersResponse {
    email:                     string;
    userName:                  string;
    accountName:               string;
    accountId:                 string;
    userId:                    string;
    categorizationAttribute:   string;
    categorizationDisplayName: string;
    userAttribute:             string;
    userDisplayName:           string;
    defaultDateRange:          string;
    categorizationJson:        string;
    statusJson:                string;
    permissionJson:            string;
    assignmentAttr:            null;
    accountOptions:            string;
    commentJSON:               string;
    ratingJSON:                string;
    searchMode:                string;
    clientChannelLabel:        string;
    userChannelLabel:          string;
    clientChannel:             number;
    userChannel:               number;
    recordLabel:               string;
    homeFolder:                string;
    passwordExpiration:        string;
    privacyPolicyConfirmed:    boolean;
    showWelcome:               boolean;
    amazonUser:                boolean;
    optionalColumns: string;
}

export interface Permission
{
    [key: string]: string | undefined;
    listenAudio?: string;
    disableAudio?: string;
    assignAgent?: string;
    availableToAssign?: string;
    internal?: string;
    rptUserComment?: string;
    similarSearch?: string;
    searchScreen?: string;
    rptUserActivity?: string;
    rptUserAssignmentActivity?: string;
    rptCallsProcessed?: string;
    sentimentScore?: string;
    holdTimeData?: string;
    changeKeywords?: string;
    socialLogin?: string;
    socialDash?: string;
    execDash?: string;
    customCategories?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ShowCompliance?: string;
}
export interface User {
    email:                     string;
    name:                  string;
    token: string;
    loginDate?: Date;
    accountname:               string;
    accountid:                 string;
    userid:                    string;
    categorizationAttribute:   string;
    categorizationDisplayName: string;
    userAttribute:             string;
    userDisplayName:           string;
    defaultDateRange:          string;
    categorizationJson:        string;
    statusJson:                string;
    permissionJson:            {permission: string;val: string}[];
    assignmentAttr:            null;
    accountOptions:            [];
    commentJSON:               string;
    ratingJSON:                string;
    searchMode:                string;
    clientChannelLabel:        string;
    userChannelLabel:          string;
    clientChannel:             number;
    userChannel:               number;
    recordLabel:               string;
    homeFolder:                string;
    passwordExpiration?:        string;
    privacyPolicyConfirmed:    boolean;
    showWelcome:               boolean;
    amazonUser:                boolean;
    optionalColumns: string;
    staticComment?: [];
    accountSettings?: [];
    logo?: string;
    logowidth?: number;
    logoHeight?: number;
}


@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(private router: Router, private httpClient: HttpClient,
        private snackbar: MatSnackBar) {
        router.events.pipe(filter((event) => event instanceof NavigationEnd
        ))
            .subscribe((event) => {
                const navEvent = event as NavigationEnd;
                if (location.protocol !== 'https:' && location.hostname !== 'localhost' ) {
                    location.protocol = 'https:';            
                    return;
                }               
                if (navEvent.url.indexOf('login')>0) return;
                if (!this.reConnect() && navEvent.url.indexOf('login') === -1)
                    this.redirectToLogin();
            });
    }

   
    settings = {
        isTrends: true,
        newAccount: false,
        newAccountS: false,
        production:0
    };

    userSessionData!: User;
    permission: Permission ={};
    accountSettings: {
        [key: string]: string| undefined;
    }={};
    apiToken!: string;
    timeout= false;
    passwordExpiration = false;
    loginDate!: Date;
    userLogin: BehaviorSubject<boolean> = new BehaviorSubject(false as boolean);
   
    redirectToLogin(): void {       
        this.router.navigateByUrl('/login');
    }

    reConnect = (): boolean=> {
        if (localStorage['userSession'] === undefined || localStorage['userSession'] === 'undefined') {
            window.location.replace('/login');
            return false;
        }
        if (this.isAuthenticated()) return true;
        const userSession: User = JSON.parse(localStorage['userSession']);
        this.userSessionData={
            searchMode:userSession.searchMode,
            clientChannelLabel:userSession.clientChannelLabel,
            userChannelLabel:userSession.userChannelLabel,
            clientChannel:userSession.clientChannel,
            userChannel:userSession.userChannel,
            recordLabel:userSession.recordLabel,
            name : userSession.name,
            userid : userSession.userid,
            email : userSession.email,
            token : userSession.token,
            accountname : userSession.accountname,
            accountid : userSession.accountid,
            loginDate : userSession.loginDate,
            categorizationAttribute : userSession.categorizationAttribute,
            categorizationDisplayName : userSession.categorizationDisplayName,
            userAttribute : userSession.userAttribute,
            userDisplayName : userSession.userDisplayName,
            defaultDateRange : userSession.defaultDateRange,
            categorizationJson : userSession.categorizationJson,
            statusJson : userSession.statusJson,
            commentJSON : userSession.commentJSON,
            staticComment : userSession.commentJSON.length > 0 ? JSON.parse(userSession.commentJSON) : [],
            permissionJson : userSession.permissionJson,
            ratingJSON : userSession.ratingJSON,
            homeFolder : userSession.homeFolder,
            accountOptions : userSession.accountOptions,
            assignmentAttr : userSession.assignmentAttr,
            privacyPolicyConfirmed : userSession.privacyPolicyConfirmed,
            passwordExpiration : userSession.passwordExpiration,
            amazonUser : userSession.amazonUser,
            optionalColumns: userSession.optionalColumns,
            showWelcome : userSession.showWelcome
        };
       
       
        this.userSessionData.accountOptions.forEach((accountSetting: {setting: string;val: string})=> {
            this.accountSettings[accountSetting.setting] = accountSetting.val;
        });
        this.userSessionData.permissionJson.forEach((permission: {permission: string;val: string})=> {
            this.permission[permission.permission] = permission.val;
        });       
        if (this.settings.production === 0) {
            this.permission.similarSearch = 'true';
            this.permission.searchScreen = 'true';
        }
        if (this.userSessionData.accountid === '21') {
            this.userSessionData.logo = 'content/images/ms.png';
            this.userSessionData.logowidth = 350;
        } else if (this.accountSettings.template === 'Sentio360') {
            this.userSessionData.logo = 'content/images/Sentio.png';
            this.userSessionData.logowidth = 350;
        } else if (this.accountSettings.template === 'callTek') {
            this.userSessionData.logo = 'content/images/calltek_logo.jpg';
            this.userSessionData.logowidth = 50;
            this.userSessionData.logoHeight = 50;
        } else {
            this.userSessionData.logo = 'content/images/logo.png';
            this.userSessionData.logowidth = 150;
        }

        // expire after some time???
        this.loginDate = new Date();
        this.apiToken = userSession.token;
        this.userLogin.next(true);
        return true;
    };
    isAuthenticated(): boolean{ 
        return this.userSessionData?.userDisplayName.length>0;
    }
    isComplianceDash =(): boolean=> this.permission.ShowCompliance==='true';
    logout = (): void=> {
        this.userTrack({
            audioid: '',
            action: 'logout',
            value: '',
            isupdate:0
        });
        window.onbeforeunload = null;
           
        localStorage.removeItem('userSession');
        window.location.replace('/login');
        this.timeout = true;
       
    };
    login = (credentials: {userName: string; password: string}): void=>{
        this.httpClient.post<UserResponse >('api/login/loginUser', {        
            params:{  
                user: credentials.userName,
                password: credentials.password
            }
        }).subscribe((response: UserResponse): void=>{
            this.snackbar.open('Login Successful','Dismiss', {               
                verticalPosition: 'top', 
                horizontalPosition: 'right',
                duration: 2000,
                panelClass:'success'
            });   
            const userSession: User=
                {
                    name: response.user.userName,
                    userid:response.user.userId,
                    email:response.user.email,
                    accountname:response.user.accountName,
                    accountid:response.user.accountId,
                    categorizationAttribute:response.user.categorizationAttribute,
                    categorizationDisplayName:response.user.categorizationDisplayName,
                    userAttribute:response.user.userAttribute,
                    userDisplayName:response.user.userDisplayName,
                    defaultDateRange:response.user.defaultDateRange,
                    categorizationJson:response.user.categorizationJson,
                    statusJson:response.user.statusJson,
                    commentJSON:response.user.commentJSON,
                    permissionJson:JSON.parse(response.user.permissionJson),
                    accountOptions:JSON.parse(response.user.accountOptions),
                    ratingJSON:JSON.parse(response.user.ratingJSON),
                    assignmentAttr:response.user.assignmentAttr,
                    token:response.token,
                    homeFolder:response.user.homeFolder,
                    searchMode:response.user.searchMode,
                    clientChannelLabel:response.user.clientChannelLabel,
                    userChannelLabel:response.user.userChannelLabel,
                    clientChannel:response.user.clientChannel,
                    userChannel:response.user.userChannel,
                    recordLabel:response.user.recordLabel,
                    privacyPolicyConfirmed:response.user.privacyPolicyConfirmed,
                    amazonUser:response.user.amazonUser,
                    optionalColumns:response.user.optionalColumns,
                    showWelcome:response.user.showWelcome
                };
            if (response.user.passwordExpiration) {
                const expDate = new Date(response.user.passwordExpiration).getTime();
                const today = new Date().getTime();
                if (expDate < today) {
                    this.passwordExpiration = true;
                } else {
                    this.passwordExpiration = false;
                }
            }
            this.loginDate = new Date();
            localStorage.setItem('userSession', JSON.stringify(userSession)); 
          
            //  this.userLogin.next(true); 
            if (this.reConnect()) {              
                   this.userTrack({
                audioid: '',
                action: 'login',
                value: ''                
            });  
                this.router.navigateByUrl('/');
            }
                          
        },(err)=>{
            this.snackbar.open('Error Logging In','Dismiss', {               
                verticalPosition: 'top', 
                horizontalPosition: 'right',
                duration: 2000,
                panelClass:'error'
            });   
            console.log(err);            
            return false;
        });
    };
    
    userTrack = (info: {audioid: string;action: string;value: string;isupdate?: number}): void=> {
        this.httpClient.get('/api/logging/userTracking', {
            params: {
                apitoken: this.userSessionData.token,
                userid: this.userSessionData.userid,
                audioid: info.audioid,
                action: info.action,
                value: info.value,
                isupdate: (info.isupdate ? info.isupdate : 0).toString()
            }
        }).subscribe((response) => {
            console.log(response);
        });
    };
}


