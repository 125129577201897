import { Component, OnInit } from '@angular/core';
import {DashService,CurrentKeywordValues} from '../../services/dash.service';

@Component({
    selector: 'app-dash-display-keywords',
    templateUrl: './dash-display-keywords.component.html',
    styleUrls: ['./dash-display-keywords.component.css']
})
export class DashDisplayKeywordsComponent implements OnInit {

    constructor(public dashService: DashService) { }

    ngOnInit(): void {
    }

    removeKeyword(keyword: CurrentKeywordValues): void{
        this.dashService.currentKeywordValues = 
        this.dashService.currentKeywordValues.filter(item => {
            const same = item.keyword === keyword.keyword && item.operator===keyword.operator && keyword.stereo===item.stereo;
            return !same;
        });

        this.dashService.reloadData();    
    }
}
