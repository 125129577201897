<div class="container" style="">
    <div fxLayout="row" class="example-header flx">
        <h2 fxFlex="auto" class="text-lucid">Keyword and Phrases</h2>
        <div fxFlex="20%">
        </div>
        <mat-form-field appearance="fill" fxFlex="30%">
            <mat-label>Search</mat-label>
            <input matInput (keydown.enter)="getFreeSearchResults($event)" (keyup)="applyFilter($event)">
        </mat-form-field>
        <div fxFlex="10%">
            <button style="float: right" matTooltip="Export Data" color="primary" matTooltipPosition="above" mat-mini
                (click)="exporter.exportTable('csv',{fileName:'keywords'})">
                <mat-icon>file_download</mat-icon>
            </button>
        </div>
    </div>

    <div fxLayout="row" style="overflow:auto;height:345px">
        <table matTableExporter [hiddenColumns]="[5]" #exporter="matTableExporter" mat-table matSort [dataSource]="dataSource"
            class="mat-elevation-z8 category-table">
            <ng-container matColumnDef="keyword">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Keyword </th>
                <td mat-cell *matCellDef="let element"> {{element.keyword}} </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="callsBoth">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Calls with Keyword </th>
                <td mat-cell *matCellDef="let element"> {{element.calls}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="mentionsBoth">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Mentions </th>
                <td mat-cell *matCellDef="let element"> {{element.mentions}} </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="calls">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Agent Calls with Keyword </th>
                <td mat-cell *matCellDef="let element"> {{element.calls}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="mentions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Agent Mentions </th>
                <td mat-cell *matCellDef="let element"> {{element.mentions}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="calls2">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Calls </th>
                <td mat-cell *matCellDef="let element"> {{element.calls2}} </td>
            </ng-container>

            <ng-container matColumnDef="mentions2">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Mentions </th>
                <td mat-cell *matCellDef="let element"> {{element.mentions2}} </td>
            </ng-container>

            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <div>Select</div>
                    <mat-form-field class="role" appearance="legacy" *ngIf="dashService.stereoConfig().isTwoChannel===1">
                        <mat-select [(value)]="dashService.selectKeywordRole" (selectionChange)="selectKeywordRoleChange();">
                            <mat-option value="Agent">
                                Agent
                            </mat-option>
                            <mat-option value="Customer">
                                Customer
                            </mat-option>
                            <mat-option value="Both">
                                Both
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon (click)="selectKeyword(element, true)" style="cursor: pointer;color: #3a8fc8;">add_circle</mat-icon>
                    <mat-icon (click)="selectKeyword(element, false)" style="cursor: pointer;color: #3a8fc8;">remove_circle</mat-icon>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 20,1000]" showFirstLastButtons></mat-paginator>
</div>