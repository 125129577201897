<div fxLayout="row" style="background-color:rgba(245, 245, 245, 0.7)" *ngIf="showQueryBuilder">
    <div fxFlex="10%">
    </div>
    <div fxFlex="80%">
        <app-query-builder></app-query-builder>
    </div>
</div>

<app-dash-display-top-box *ngIf="!dashService.isComplianceDash()">
</app-dash-display-top-box>
<app-dash-display-top-box-compliance *ngIf="dashService.isComplianceDash()">
</app-dash-display-top-box-compliance>
<div fxLayout="row" style="background-color:rgba(245, 245, 245, 0.7)">
    <div fxFlex="2%">
    </div>
    <div fxFlex="95%">
        <app-dash-display-top-box-compliance-totals *ngIf="dashService.isComplianceDash()">
        </app-dash-display-top-box-compliance-totals>
    </div>
</div>

<div class="panel-body" style="padding-bottom:30px;background-color:rgb(230, 230, 230)">
    <div fxLayout="row">
        <div fxFlex="1%"></div>
        <div fxFlex="200px">
            <h5 class="ng-binding">Date
                <span class="semi-bold ">Selected</span>
            </h5>
            <app-dash-date-picker></app-dash-date-picker>
        </div>
        <div fxFlex="2%">
        </div>
        <div fxFlex="35%" *ngIf="displaySelectedCategories()">
            <h5 class="ng-binding">Category
                <span class="semi-bold ">Selected</span>
            </h5>
            <app-dash-display-categories></app-dash-display-categories>
        </div>
        <div fxFlex="2%">
        </div>
        <div fxFlex="auto" *ngIf="displaySelectedKeywords()">
            <h5 class="ng-binding">Keyword
                <span class="semi-bold ">Selected</span>
            </h5>
            <app-dash-display-keywords></app-dash-display-keywords>
        </div>
        <div fxFlex="2%">
        </div>
        <div *ngIf="displaySelectedCompliances()" fxFlex="auto">
            <h5 class="ng-binding">Compliance
                <span class="semi-bold ">Selected</span>
            </h5>
            <app-dash-display-compliances></app-dash-display-compliances>
        </div>
        <div fxFlex="1%">
        </div>
    </div>
    <div *ngIf="true" fxLayout="row">
        <div fxFlex="auto">
        </div>
        <div fxFlex="10%">
            <button [matTooltip]="dashService.isNonCompliant?'Show All':'Show Only Non-Compliant'" color="primary" matTooltipPosition="above" mat-mini
                (click)="dashService.isNonCompliantToggle()" [ngStyle]="{'background-color':dashService.isNonCompliant?'red':''}">
                {{dashService.isNonCompliant?'Show All':'Show Non-Compliant'}}
            </button>
        </div>
        <div fxFlex="10%">
            <button [matTooltip]=dashService.downloadButtonText color="primary" matTooltipPosition="above" mat-mini (click)="dashService.downloadWords()">
                {{dashService.downloadButtonText}}
            </button>
        </div>
        <div fxFlex="10%">
            <button [ngStyle]="{'background-color':dashService.advancedQuery.sql.length>0?'red':''}" matTooltip="Build Query" color="primary"
                matTooltipPosition="above" mat-mini (click)="openQueryBuilder()">
                Query Builder
            </button>
        </div>
        <div fxFlex="1%"></div>
    </div>
</div>

<div fxLayout="row" style="background-color:rgba(245, 245, 245, 0.7)">
    <div fxFlex="2%">
    </div>
    <div fxFlex="auto">
        <div style="margin-top:60px" *ngIf="dashService.isComplianceDash()">

        </div>
        <app-dash-display-category-table>
        </app-dash-display-category-table>
    </div>
    <div fxFlex="2%">
    </div>
    <div fxFlex="auto">
        <app-dash-display-keyword-table *ngIf="!dashService.isComplianceDash()">
        </app-dash-display-keyword-table>
        <mat-tab-group *ngIf="dashService.isComplianceDash()">
            <mat-tab label="Compliance">
                <app-dash-display-compliance-table>
                </app-dash-display-compliance-table>
            </mat-tab>
            <mat-tab label="Keyword">
                <app-dash-display-keyword-table>
                </app-dash-display-keyword-table>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div fxFlex="2%">
    </div>
</div>
<div fxLayout="row" style="display:none">
    <div fxFlex="1%"></div>
    <div fxFlex="98%">
        <mat-card>
            <mat-card-header style="display:unset">
                <div fxLayout="row" style="align-items:center">
                    <h3 fxFlex="10%">
                        View <span style="font-weight: 400">Calls</span>
                    </h3>

                    <div fxFlex="20%">

                    </div>
                    <div fxFlex="auto">
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Search File Name
                            </mat-label>
                            <input matInput color="primary" (change)="dashService.reloadData()" [(ngModel)]="dashService.fileName" />
                        </mat-form-field>
                    </div>
                    <div fxFlex="20%">
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Search Comments
                            </mat-label>
                            <input matInput color="primary" (change)="dashService.reloadData()" [(ngModel)]="dashService.commentField" />
                        </mat-form-field>
                    </div>
                    <div matTooltip="Only show calls with Comments" matTooltipPosition="above" fxFlex="auto">
                        Comments Only
                        <mat-checkbox color="primary" (change)="dashService.reloadData()" [(ngModel)]="dashService.comment"></mat-checkbox>
                    </div>
                    <div fxFlex="3%">
                        <button matTooltip="Export Data" color="primary" matTooltipPosition="above" mat-mini (click)="exportGrid()">
                            <mat-icon>file_download</mat-icon>
                        </button>
                    </div>
                    <div fxFlex="1%"></div>
                    <div fxFlex="3%">
                        <button matTooltip="Reload Data" color="primary" matTooltipPosition="above" mat-mini (click)="this.dashService.reloadData()">
                            <mat-icon>refresh</mat-icon>
                        </button>
                    </div>
                    <div fxFlex="1%"></div>
                    <div #copyLink fxFlex="3%">
                        <button matTooltip="Copy Query to URL" color="primary" matTooltipPosition="above" mat-mini (click)="saveTinyLink()">
                            <mat-icon>content_copy</mat-icon>
                        </button>
                    </div>
                </div>
            </mat-card-header>
            <mat-card-content>
                <ag-grid-angular style=" height: 500px;" class="ag-theme-alpine" (gridReady)="dashService.onGridReady($event)"
                    [gridOptions]="dashService.gridOptions" [columnDefs]="dashService.columnDefs">
                </ag-grid-angular>
            </mat-card-content>
        </mat-card>
    </div>
    <div fxFlex="1%"></div>
</div>