<div fxLayout="row wrap" fxLayoutGap="20px" *ngIf="dashService.complianceDashTotals" style="margin-left:20px">
    <div fxFlex="23%" class="widget-8 tiles blue border5">
        <div class="tiles-body st-right" style="height: 120px;">
            <div class="wrapper transparent" style="margin-top:5%;text-align: center;font-size: 32px;">
                <span class="item-count semi-bold">{{'Total '+dashService.formatMediaType(1) }}</span>
            </div>
            <div class="wrapper" style="margin-top:5%;text-align: center">
                <span class="item-count semi-bold" style="font-size: 32px;">{{dashService.complianceDashTotals.audioCount}}</span>
            </div>
        </div>
    </div>
    <div fxFlex="23%" class="widget-8 panel border5 bg-success ">
        <div fxLayout="row" class="container-xs-height full-height">
            <div fxFlex="100%" fxLayoutAlign="center" style="margin-top:5%;font-size: 32px;">
                <span class="item-count semi-bold">Duration</span>
            </div>
        </div>
        <div fxLayout="row" *ngIf="dashService?.complianceDashTotals" class="row-xs-height">
            <div fxFlex="100%" fxLayoutAlign="center">
                <h3 class="" style="font-size: 32px;text-align: center;">{{dashService.formatTime(dashService.complianceDashTotals.audioDuration)}} </h3>
            </div>
        </div>
    </div>
    <div fxFlex="23%" class="widget-8 panel border5 bg-success ">
        <div fxLayout="row" class="container-xs-height full-height">
            <div fxFlex="100%" fxLayoutAlign="center" style="margin-top:5%;font-size: 32px;">
                <span class="item-count semi-bold">Opportunities</span>
            </div>
        </div>
        <div fxLayout="row" *ngIf="dashService?.complianceDashTotals" class="row-xs-height">
            <div fxFlex="100%" fxLayoutAlign="center">
                <h3 class="" style="font-size: 32px;text-align: center;">{{dashService.complianceDashTotals.complianceOpportunities?.toFixed(0)}} </h3>
            </div>
        </div>
    </div>
    <div fxFlex="23%" class="widget-8 panel border5 bg-success ">
        <div fxLayout="row" class="container-xs-height full-height">
            <div fxFlex="100%" fxLayoutAlign="center" style="margin-top:5%;font-size: 32px;">
                <span class="item-count semi-bold">Complied</span>
            </div>
        </div>
        <div fxLayout="row" *ngIf="dashService?.complianceDashTotals" class="row-xs-height">
            <div fxFlex="100%" fxLayoutAlign="center">
                <h3 class="" style="font-size: 32px;text-align: center;">{{dashService.complianceDashTotals.complied?.toFixed(0)}} </h3>
            </div>
        </div>
    </div>
</div>