import { ChangeDetectorRef } from '@angular/core';
import { NgZone } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import {DashService} from '../../services/dash.service';

@Component({
    selector: 'app-dash-display-top-box-compliance-totals',
    templateUrl: './dash-display-top-box-compliance-totals.component.html',
    styleUrls: ['./dash-display-top-box-compliance-totals.component.css']
})
export class DashDisplayTopBoxComplianceTotalsComponent implements OnInit {
    drillDown: {catval: string;splitkey: [{pct: number}]}[] =  [];
    fullData={};
    drillOpen=false;
    drillOpenLoad = false;
    category='';
    
    constructor(
        public ngZone: NgZone,
        public changeDetectorRef: ChangeDetectorRef,
        public dashService: DashService
    ) { }

    ngOnInit(): void {
        this.dashService.complianceTotalsUpdated.subscribe(data => {
            if (data.totals.length<1) return;
            this.drillDown =[];
            this.fullData = {};
            this.category=this.dashService.selectedCategory;       
            this.dashService.complianceTotals.allData.forEach(data=>{
                if (data.Category!=='Total') {
                    if (this.fullData[data.Category]===undefined) this.fullData[data.Category]={};
                    if (this.fullData[data.Category][data.CatVal]===undefined) this.fullData[data.Category][data.CatVal]={};
                    this.fullData[data.Category][data.CatVal][data.splitdata]=data;
                }
            });
            if (this.fullData[this.category]===undefined || this.fullData[this.category].length===0) {
                this.drillOpen = false;
                return;
            }
            Object.keys(this.fullData[this.category]).forEach(catval=>{
                const value = {catval:catval,splitkey: [] as any};
                this.dashService.complianceTotals.splitKeys.forEach(key=>{
                // eslint-disable-next-line @typescript-eslint/naming-convention
                    if ( this.fullData[this.category][catval][key]===undefined) this.fullData[this.category][catval][key]={CatVal:catval,splitdata:key,pct:null};
                    value.splitkey.push( this.fullData[this.category][catval][key]);
                });           
                this.drillDown.push(value);
            });
        });
    }

    expandTotals(): void{
        if (this.drillOpen) {
            // this.drillDown =[];
            this.drillOpen = false;
            return;
        }     
        this.ngZone.run(()=> this.drillOpenLoad = true);
        setTimeout(()=>{
            this.drillOpen = true;  
            this.drillOpenLoad = false;
            // if (this.category === this.dashService.selectedCategory) return;     
            this.drillDown =[];
            this.category=this.dashService.selectedCategory;       
            this.dashService.complianceTotals.allData.forEach(data=>{
                if (data.Category!=='Total') {
                    if (this.fullData[data.Category]===undefined) this.fullData[data.Category]={};
                    if (this.fullData[data.Category][data.CatVal]===undefined) this.fullData[data.Category][data.CatVal]={};
                    this.fullData[data.Category][data.CatVal][data.splitdata]=data;
                }
            });
            if (this.fullData[this.category]===undefined || this.fullData[this.category].length===0) {
                this.drillOpen = false;
                return;
            }
            Object.keys(this.fullData[this.category]).forEach(catval=>{
                const value = {catval:catval,splitkey: [] as any};
                this.dashService.complianceTotals.splitKeys.forEach(key=>{
                // eslint-disable-next-line @typescript-eslint/naming-convention
                    if ( this.fullData[this.category][catval][key]===undefined) this.fullData[this.category][catval][key]={CatVal:catval,splitdata:key,pct:null};
                    value.splitkey.push( this.fullData[this.category][catval][key]);
                });           
                this.drillDown.push(value);
            });
        },0);
     
    }
}
