/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { GridOptions,CellClickedEvent,IServerSideDatasource,ServerSideStoreType, IServerSideGetRowsParams,ICellRendererParams,ColDef } from '@ag-grid-community/core';
import { HttpClient , HttpHeaders} from '@angular/common/http';
import {AuthService} from '../services/auth.service';
import * as dayjs from 'dayjs';
import { Dayjs } from 'dayjs';

import {BehaviorSubject} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {TranscriptWindowComponent} from '../dialogs/transcript-window/transcript-window.component';
import { NgxSpinnerService } from 'ngx-spinner';
import {TransferService, TransferPayload} from '../services/transfer.service';
import {getGuid} from '../services/utility.service';
import * as LogRocket from 'logrocket';
import { MatSnackBar } from '@angular/material/snack-bar';
export interface SelectedTranscript {
    rownum:                  string;
    accountid:               string;
    audioid:                 string;
    asrid:                   string;
    duration:                number;
    filename:                string;
    filedate:                string;
    audiolink:               string;
    status:                  string;
    uniqueKeywords:          number;
    selectedKeywordMentions: number;
    impactScore:             number;
    userRating:              null;
    channels:                number;
    comments:                string [];
    sentiment:               null;
    mediaType:               number;
    ivrTime:                 number;
    holdTime:                number;
    agentTime:               number;
    mediaTypeGroup:          string;
    mediaTypeLabel:          string;
    wand:                    number;
    s3Link?: string; 
}

interface DashDate {
    startDate: Dayjs;
    endDate: Dayjs;
}
interface Categories {
    category: string;
    title: string;
    values: string [];
    color: string;
}
export interface CategoryList {
    audioCount: number;
    audioDuration: number;
    audioWithKeywordCount: number;
    category: string;
    CategoryHeading: string;
    keywordCount: number;
    sentiment: number;   
    complianceOpportunities?: number;
    complied?: number;   
    compliancePercent?: number;
}


export interface KeywordList {
    keyword:   string;
    calls:     number;
    mentions:  number;
    calls2:    number;
    mentions2: number;
}

export interface ComplianceList{
    complianceName: string;
    complianceOpportunities: number;
    complianceResultAttr: string;
    complied: number;
    compliancePercent?: number;
}

export interface CurrentComplianceValues{
    compliance: string;
    operator: string;
    stereo: string;   
}
export interface CurrentKeywordValues{
    keyword: string;
    operator: string;
    stereo: string;   
}

interface StereoConfig {
    isTwoChannel: number;
    channel1: string;
    channel2: string;
    label: string [];
}

interface ComplianceDashTotals {
    audioCount: number;
    complied: number;
    audioDuration: number;
    complianceOpportunities: number;
}
interface DashTotals {
    IVRMinutes: number;
    agentMinutes: number;
    audioCount: number;
    audioDuration: number;
    audioWithKeywordCount: number;
    audioWithKeywordReviewedCount: number;
    avgSentiment: number;
    holdMinutes: number;
    keywordCount: number;
    mediaType: string;
    pctAudioWithKeyword: number;
    resultCount: number;
    reviewedCount: number;
    seq: number;
}
interface ComplianceTrends {
    gauge1: number;
    gauge2: number;
}

interface ComplianceTotals {
    totals: {Category: string;CatVal: string;id: number;pct: number;splitdata: string}[];
    splitKeys: string [];
    allData: {Category: string;CatVal: string;id: number;pct: number;splitdata: string}[];
}


@Injectable({
    providedIn: 'root'
})
export class DashService {
    cacheguid='';   
    statusOptions = '';
   
    selectedDate: DashDate = {startDate: dayjs().subtract(7,'day').startOf('d'),endDate: dayjs().subtract(0,'day').endOf('d')};
    currentCategoryValues: {[key: string]: Categories} ={};
    selectedCategory!: string;
    selectedKeyword!: string;
    dashTotals!: DashTotals;
    complianceDashTotals!: ComplianceDashTotals;
    complianceTrends!: ComplianceTrends;
    dashTotalsSource: { [key: string]: DashTotals}={};
    mediaTypeGroup = '';
    complianceFreeSearch: string | null = null;
    comment!: boolean;
    commentField='';
    fileName='';
    sFilter=0;
    isNonCompliant = 0;
    advancedQuery={sql:'',json:[{}]};
    freeKeywordList: KeywordList[] = [];
    complianceTotals: ComplianceTotals={totals:[],splitKeys:[],allData: []};
    complianceTotalsUpdated: BehaviorSubject<ComplianceTotals> = new BehaviorSubject(this.complianceTotals);
    complianceLoaded = true;
    categoryListLoaded = true;
    whereHint = '';
    
    get sFilterBool(): boolean {
        return this.sFilter === 1;
    }
   
    set sFilterBool(newValue: boolean) {
        this.sFilter = newValue ? 1 : 0;
    }
   

    constructor(
        public snackbar: MatSnackBar,
        private httpClient: HttpClient,
        private authService: AuthService,
        public matDialog: MatDialog,
        private spinner: NgxSpinnerService,
        private transferService: TransferService
    ) { 
        this.authService.userLogin.subscribe(loggedIn=>{
            if (loggedIn){
               
                let startDate= this.authService.userSessionData.defaultDateRange[0].split(' ')[0];
                if (startDate==='t') startDate = '0';
                this.selectedDate.startDate=dayjs().subtract(parseInt(startDate,10),'day');          
                this.cacheguid = '';
                if (this.selectKeywordRole === '' && this.authService.userSessionData.clientChannelLabel.length>0) 
                    this.selectKeywordRole = this.authService.userSessionData.clientChannelLabel;
                JSON.parse(this.authService.userSessionData.statusJson).forEach((status: {status: string})=> {
                    this.statusOptions = this.statusOptions + '<option value="' + status.status + '">' + this.iTranslate(status.status) + '</option>';
                });  
                this.selectedCategory = this.authService.userSessionData.categorizationAttribute;
                this.createCategories();
                LogRocket.identify(this.authService.userSessionData.userid, {
                    name: this.authService.userSessionData.name,
                    email: this.authService.userSessionData.email                 
                });
            }
        }) ;           
    }


    grid!: GridOptions;
    columnDefs: ColDef [] = [{
        headerName: 'Row',
        field: 'rownum',
        resizable: true,
        maxWidth: 65,
        sortable: false,
        minWidth: 75,        
        cellRenderer: (params: ICellRendererParams): string=> {
            if (params.data == null) {
                return '';
            }
            let style = '';
            if (params.data.comments != null && params.data.comments.length > 0) {
                let title = '';
                title = params.data.comments.replace('||', '\n');
                title = title.replace('\"', '\'');
                title = title.replace('"', '\'');
                style = 'title ="' + title + '" style="color:red"';
            }
            return '<center ' + style + '>&nbsp&nbsp&nbsp&nbsp' + params.data.rownum + '&nbsp&nbsp&nbsp</center>';
        }
    }, {
        headerName: 'Date',
        field: 'filedate',
        resizable: true,
        sortable: true,
        unSortIcon:true,
        minWidth: 150, 
        maxWidth: 175, 
        cellRenderer: (params: ICellRendererParams): string => {
            if (params.data == null) {
                return '';
            }
            let title = '';
            if (params.data.comments != null && params.data.comments.length > 0) {              
                title = params.data.comments.replace('||', '\n');
                title = title.replace('\"', '\'');
                title = title.replace('"', '\'');
            }
            const date = new Date(params.data.filedate);
            return '<center title="' + title + '" >' + date.toLocaleString() + '</center>';
        }
    }, {
        headerName: 'File Name',
        field: 'filename',         
        sortable: true,
        unSortIcon:true,
        resizable: true,
        minWidth: 85,
        maxWidth: 895,
        cellRenderer: (params: ICellRendererParams): string=> {
            if (params.data == null) {
                return '';
            }
            return '<center>' + params.data.filename + '</center>';
        }
    }, {
        headerName: 'Duration',
        headerTooltip:'Duration',
        field: 'duration',
        resizable: true,
        sortable: true,
        unSortIcon:true,
        minWidth: 65,  
        maxWidth: 125,           
        cellRenderer: (params: ICellRendererParams): string=> {
            if (params.data == null) {
                return '';
            }
            let data;
            data = params.data.duration;
            if (params.data.mediaType === 0) data = this.formatTime(params.data.duration);
            return '<center>' + data + '</center>';
        }
    },
    {
        headerName: 'Media Type',
        headerTooltip:'Media Type',
        field: 'mediaTypeLabel',
        resizable: true,
        sortable: true,
        unSortIcon:true,
        minWidth: 90,   
        maxWidth: 130,    
        cellRenderer: (params: ICellRendererParams): string=> {
            if (params.data == null) {
                return '';
            }
            return '<center>' + this.iTranslate(params.data.mediaTypeLabel) + '</center>';
        }
    },
    {
        headerName: 'Experience',
        headerTooltip:'Experience',
        field: 'sentiment',
        resizable: true,
        sortable: true,
        unSortIcon:true,
        minWidth: 100,  
        maxWidth: 150,   
        cellRenderer: (params: ICellRendererParams): string=> {
            if (params.data == null) {
                return '';
            }
            return '<center>' +this.displayPercent(params.data.sentiment) + '</center>';
        }
    },
    {
        headerName: 'Unique Keywords',
        field: 'uniqueKeywords',
        resizable: true,
        minWidth: 120,
        sortable: true,
        unSortIcon:true,
        cellRenderer: (params: ICellRendererParams): string=> {
            if (params.data == null) {
                return '';
            }
            return '<center>' + params.data.uniqueKeywords + '</center>';
        }
    }, {
        headerName: 'Selected Keywords',
        field: 'selectedKeywordMentions',
        resizable: true,
        sortable: true,
        unSortIcon:true,
        minWidth: 130,    
        maxWidth: 170, 
        cellRenderer: (params: ICellRendererParams): string=> {
            if (params.data == null) {
                return '';
            }
            return '<center>' + params.data.selectedKeywordMentions + '</center>';
        }
    }, {
        headerName: 'IVR Time',
        field: 'ivrTime',
        resizable: true,
        minWidth: 105,
        maxWidth: 105,
        sortable: true,
        unSortIcon:true,
        hide: this.authService.permission.holdTimeData !== 'true',
        cellRenderer: (params: ICellRendererParams): string=> {
            if (params.data == null) {
                return '';
            }
            if (params.data.ivrTime == null) {
                return '<center>N/A</center>';
            }
            return '<center>' + this.formatTime(params.data.ivrTime) + '</center>';
        }
    }, {
        headerName: 'Hold Time',
        field: 'holdTime',
        resizable: true,
        unSortIcon:true,
        minWidth: 105,
        maxWidth: 105,
        sortable: true,
        hide: this.authService.permission.holdTimeData !== 'true',
        cellRenderer: (params: ICellRendererParams): string=> {
            if (params.data == null) {
                return '';
            }
            if (params.data.holdTime == null) {
                return '<center>N/A</center>';
            }
            return '<center>' + this.formatTime(params.data.holdTime) + '</center>';
        }
    }, {
        headerName: 'Agent Time',
        field: 'agentTime',
        resizable: true,
        unSortIcon:true,
        minWidth: 125,
        maxWidth: 125,
        sortable: true,
        hide: this.authService.permission.holdTimeData !== 'true',
        cellRenderer: (params: ICellRendererParams): string=> {
            if (params.data == null) {
                return '';
            }
            if (params.data.agentTime == null) {
                return '<center>N/A</center>';
            }
            return '<center>' + this.formatTime(params.data.agentTime) + '</center>';
        }
    }, {field:'GUID',hide:true}, {
        headerName: 'Status',
        field: 'status',
        resizable: true,
        editable: true,
        cellEditor: 'agRichSelectCellEditor',
        refData:{'Viewed':'Viewed','Not Viewed':'Not Viewed','Flagged':'Flagged','Followed Up':'Followed Up'},
        cellEditorParams: {
            values: ['Viewed','Not Viewed','Flagged','Followed Up']                              
        },
        sortable: true,
        unSortIcon:true,
        minWidth: 55,
        onCellValueChanged:(params): void=>{
            if (params.newValue!==params.oldValue){
                this.httpClient.get<{success: boolean}>('/api/dashboard/updateRowStatus', {
                    params: {
                        audioid: params.data.audioid,
                        status: params.newValue,
                        userid: this.authService.userSessionData.userid
                    }
                }).subscribe((response: {success: boolean})=>{                    
                    if (response?.success) {
                        this.snackbar.open('Status Saved','Dismiss', {               
                            verticalPosition: 'top', 
                            horizontalPosition: 'right',
                            duration: 2000,
                            panelClass:'success'
                        }); 
                    }
                    else {
                        this.snackbar.open('Error Saving','Dismiss', {               
                            verticalPosition: 'top', 
                            horizontalPosition: 'right',
                            duration: 2000,
                            panelClass:'error'
                        }); 
                    }
                });
            }            
        } 
    }, {
        headerName: 'Review',
        headerTooltip:'Review',
        field:'review',
        sortable: false,
        pinned: 'right', 
        resizable: true,
        minWidth:70,
        maxWidth:100,
        onCellClicked:(params: CellClickedEvent): void=>{    
            this.matDialog.open(TranscriptWindowComponent, {
                height: '90%',
                width: '80%',
                data: {selectedTranscript:params.data as SelectedTranscript,
                    dashService:this,
                    node:this.grid.api?.getRowNode(params.node.id as string)
                },
                panelClass: 'transcript-container'
            });
            
        },
        cellRenderer: (params: ICellRendererParams): string=> {
            if (params.data == null) {
                return '';
            }
            return `<mat-icon class="mat-icon material-icons mat-icon-no-color" 
            role="img" aria-hidden="true" style="cursor: pointer;color: #3a8fc8;margin-top:4px">
            play_circle_filled</mat-icon>`;
        }
    }, {       
        field: 'comments',
        hide: true    
    }
    ];
   
    resizeGrid(): void{
        if (!this.grid) return;
        const colIds = this.grid.columnApi?.getAllDisplayedColumns()
            .map(col => col.getColId());
        this.grid.api?.sizeColumnsToFit();
    //    this.grid.columnApi?.autoSizeColumns(colIds || []); 
    //    this.grid.columnApi?.autoSizeAllColumns(); 
    }

    lazyLoadDashboard(params){
        const guidAtStart = this.cacheguid;
        if (params.request.sortModel[0]) {
            this.sortColumn = params.request.sortModel[0].colId;
            this.sortDir = params.request.sortModel[0].sort;
        }        
        this.createCategoryList([]);
        this.createComplianceList([]);
        this.complianceLoaded=false;
        this.categoryListLoaded=false;
        this.complianceTotals.totals=[];
        this.complianceTotalsUpdated.next(this.complianceTotals); 
        const options: Record<string,any> ={
            params: {
                cacheguid:this.cacheguid,
                categorizationFilterJson:this.createCategorySelectedJSON(),
                enddate:this.selectedDate.endDate.format('YYYY-MM-DD'),
                endrow:params.request.endRow?.toString() || '99999',
                keywordFilter: this.createKeywordSelected('Agent'),
                keywordFilter2:this.createKeywordSelected('Customer'),
                complianceFilter:this.createComplianceSelected(),
                mode:this.selectKeywordRole!=='Both'?this.authService.userSessionData.searchMode:'Single',
                sfilter:this.sFilter.toString(),
                sortcol:this.sortColumn,
                sortdir:this.sortDir,
                startdate:this.selectedDate.startDate.format('YYYY-MM-DD'),
                startrow:params.request.startRow?.toString() || '0',
                userid:this.authService.userSessionData.userid,
                filename:this.fileName,
                isNonCompliant:this.isNonCompliant,
                selectedCategory:this.selectedCategory
            }
        };
        if (this.commentField.length>0) options.params.comment=this.commentField;
        else if (this.comment) options.params.comment='';
        if (this.advancedQuery.sql.length>1) options.params.advancedQeury= this.advancedQuery.sql;           
        options.params.step='category';
        this.httpClient.post<{
            success: boolean;
            category: [];          
        }>(
            '/api/dashboardV2/getAccountStats', options)
            .subscribe((response: any)=>{ 
                this.categoryListLoaded=true;
                if (this.cacheguid!==guidAtStart) return;
                this.createCategoryList(response.category);
            });
        options.params.step='compliance';
        this.httpClient.post<{
            success: boolean;            
            compliances: [];
          
        }>(
            '/api/dashboardV2/getAccountStats', options)
            .subscribe((response: any)=>{ 
                this.complianceLoaded =true;
                if (this.cacheguid!==guidAtStart) return;
                this.createComplianceList(response.compliances);               
            });
        options.params.step='complianceSummary';
        this.httpClient.post<{
            success: boolean;          
            complianceTotals: [];
        }>(
            '/api/dashboardV2/getAccountStats', options)
            .subscribe((response: any)=>{ 
                if (this.cacheguid!==guidAtStart) return;              
                this.complianceTotals.splitKeys=[];
                if (response.complianceTotals) {
                    this.complianceTotals.allData = response.complianceTotals;
                    response.complianceTotals.forEach(totals =>{
                        if (totals.Category==='Total') {
                            this.complianceTotals.totals.push(totals);
                            this.complianceTotals.splitKeys.push(totals.splitdata);                                                                      
                        }
                    });
                    this.complianceTotalsUpdated.next(this.complianceTotals);
                }
            });     
    }


    gridOptions: GridOptions = {
      
        rowModelType: 'serverSide',
        serverSideStoreType: ServerSideStoreType.Partial,
        suppressCellSelection: true,
        rowSelection: 'multiple',
        minColWidth:0,
        suppressColumnVirtualisation:true,
        suppressRowClickSelection: false,
        headerHeight: 43,
        rowHeight: 33,
        enableBrowserTooltips:true,
        suppressMovableColumns: false,
        onFirstDataRendered:()=>{              
            if (this.authService.permission.holdTimeData === 'true')
                this.grid.columnApi?.setColumnsVisible(['ivrTime','holdTime','agentTime'], true);
            else
                this.grid.columnApi?.setColumnsVisible(['ivrTime','holdTime','agentTime'], false);
            this.resizeGrid();  
        }  
    };
    sortColumn='rownum';
    sortDir='ASC';

    dataSource: IServerSideDatasource = {        
    
        getRows: (params: IServerSideGetRowsParams)=> {
            if (params.request.sortModel[0]) {
                this.sortColumn = params.request.sortModel[0].colId;
                this.sortDir = params.request.sortModel[0].sort;
            }        
            if (params.request.endRow) this.spinner.show(); 
            const options: Record<string,any> ={
                params: {
                    cacheguid:this.cacheguid,
                    categorizationFilterJson:this.createCategorySelectedJSON(),
                    enddate:this.selectedDate.endDate.format('YYYY-MM-DD'),
                    endrow:params.request.endRow?.toString() || '99999',
             
                    keywordFilter: this.createKeywordSelected('Agent'),
                    keywordFilter2:this.createKeywordSelected('Customer'),
                    complianceFilter:this.createComplianceSelected(),
                    mode:this.selectKeywordRole!=='Both'?this.authService.userSessionData.searchMode:'Single',
                    sfilter:this.sFilter.toString(),
                    sortcol:this.sortColumn,
                    sortdir:this.sortDir,
                    startdate:this.selectedDate.startDate.format('YYYY-MM-DD'),
                    startrow:params.request.startRow?.toString() || '0',
                    userid:this.authService.userSessionData.userid,
                    filename:this.fileName,
                    isNonCompliant:this.isNonCompliant
                }
            };
            if (this.commentField.length>0) options.params.comment=this.commentField;
            else if (this.comment) options.params.comment='';
            if (this.advancedQuery.sql.length>1) options.params.advancedQeury= this.advancedQuery.sql;           
            options.params.step='main';
            if (options.params.startrow!=='0')  options.params.step='pagination';
            this.httpClient.post<{data: [];
                success: boolean;
                totals: DashTotals [] | ComplianceDashTotals [];
                category: [];
                keywords: [];
                mediaType: {mediaTypeGroup: string} [];
                trends: ComplianceTrends [];
                compliances: [];
                complianceTotals: [];
            }>(
                '/api/dashboardV2/getAccountStats', options)
                .subscribe((response: any)=>{
                    this.spinner.hide(); 
                    if (!response?.success) {
                        response.data=[];
                        this.snackbar.open('Error with Query','Dismiss', {               
                            verticalPosition: 'top', 
                            horizontalPosition: 'right',
                            duration: 2000,
                            panelClass:'error'
                        }); 
                        // return;
                    }   
                    if (options.params.startrow==='0') this.lazyLoadDashboard(params)
                    else {
                        params.successCallback(response.data,this.complianceDashTotals.audioCount?this.complianceDashTotals.audioCount:0);
                        return;
                    };
                  
                    this.complianceFreeSearch = null;
                    this.whereHint = response.whereHint;
                    if (this.isComplianceDash()) {
                       
                        if ( response.totals  ) {
                            this.complianceDashTotals =response.totals[0] as ComplianceDashTotals;}
                        else {
                            this.complianceDashTotals.complied = 0; 
                            this.complianceDashTotals.audioCount=0;                           
                            this.complianceDashTotals.audioDuration=0;
                            this.complianceDashTotals.complianceOpportunities = 0;
                        }
                        if (!this.complianceDashTotals.complied) this.complianceDashTotals.complied = 0;
                        if (!this.complianceDashTotals.audioDuration) this.complianceDashTotals.audioDuration = 0;                  
                      params.successCallback(response.data,this.complianceDashTotals.audioCount?this.complianceDashTotals.audioCount:0);
                       
                        if ( response.trends)
                            this.complianceTrends = response.trends[0];
                        else this.complianceTrends={gauge1:0,gauge2:0};
                        this.createKeywordList(response.keywords);
                    } else 
                    {  
                        this.createKeywordList(response.keywords);
                        if (response.totals)
                            this.dashTotals = response.totals[0] as DashTotals;
                        else {
                            this.dashTotals.agentMinutes=0;
                            this.dashTotals.audioCount=0;
                            this.dashTotals.audioDuration=0;
                            this.dashTotals.audioWithKeywordCount=0;
                            this.dashTotals.audioWithKeywordReviewedCount=0;
                            this.dashTotals.avgSentiment=0;
                            this.dashTotals.holdMinutes = 0;
                            this.dashTotals.IVRMinutes=0;
                            this.dashTotals.keywordCount=0;
                            this.dashTotals.pctAudioWithKeyword=0;
                            this.dashTotals.resultCount=0;
                            this.dashTotals.reviewedCount=0;
                            response.totals = [];
                        }
                        this.mediaTypeGroup =  response.mediaType ? response.mediaType[0]?.mediaTypeGroup: '';
                        params.successCallback(response.data,this.dashTotals.resultCount?this.dashTotals.resultCount:0);
                        if (response.totals.length > 1) {
                            response.totals.shift();                       
                            (response.totals as DashTotals []).forEach((total: DashTotals)=> {
                                this.dashTotalsSource[total.mediaType] = total;
                            });
                        }}
                        this.resizeGrid();
                });           
        }
    };

    onGridReady(grid: GridOptions): void {
        this.grid=grid;
        if (this.authService.userSessionData.optionalColumns) {
            const optionColumns = JSON.parse(this.authService.userSessionData.optionalColumns);
            const addColumns: any []  = [];
            optionColumns.forEach((column)=>{
                addColumns.push({field:'opt'+column.id,
                headerName:column.displayName,
                tooltipField: 'opt'+column.id,
                sortable: true,
                unSortIcon:true,
                resizable: true});
            });
            this.columnDefs.splice(13,0,...addColumns);
            this.columnDefs =  this.columnDefs.slice(0);       
        }
        this.transferService.checkTransferURL().then((transfer)=>{
            if (!transfer)  
            {
                this.grid.api?.setServerSideDatasource(this.dataSource); 
                return;
            }
           
            this.processTransfer(transfer);
        });     
        this.transferService.checkTranscriptURL().then((transfer: SelectedTranscript)=>{
            if (!transfer) return;        
            this.matDialog.open(TranscriptWindowComponent, {
                height: '90%',
                width: '80%',
                data: {selectedTranscript:transfer as SelectedTranscript,
                    dashService:this,
                    node:null
                },
                panelClass: 'transcript-container'
            });

        });                
    }

    getFreeSearchResults(freesearch: string): Promise<boolean>{
        return new Promise( (resolve) =>{
            this.httpClient.get('/api/dashboard/AccountGetFreeSearchResults', {
                params: {
                    enddate:this.selectedDate.endDate.format('YYYY-MM-DD'),
                    freesearch: freesearch,
                    categorizationFilterJson:this.createCategorySelectedJSON(),
                    keywordFilter:   this.createKeywordSelected('Agent'),
                    keywordFilter2: this.createKeywordSelected('Customer'),
                    mode:this.selectKeywordRole!=='Both'?this.authService.userSessionData.searchMode:'Single',
                    startdate: this.selectedDate.startDate.format('YYYY-MM-DD'),
                    userid: this.authService.userSessionData.userid
                }
            }).subscribe((response)=>{
                if (response=== 'failure') {
                    resolve(false);
                    return;
                }
                this.freeKeywordList = response['keywords'];
                resolve(true);
            });          
        });
    }

    reloadData(): void {      
        this.getNewGuid();
        this.grid?.api?.setServerSideDatasource(this.dataSource); 
    }

    reloadFilter(): void { 
    
        const guidAtStart = this.cacheguid;
            
        this.createCategoryList([]);
        this.categoryListLoaded=false;
        const options: Record<string,any> ={
            params: {
                cacheguid:this.cacheguid,
                categorizationFilterJson:this.createCategorySelectedJSON(),
                enddate:this.selectedDate.endDate.format('YYYY-MM-DD'),
                endrow: '99999',
                keywordFilter: this.createKeywordSelected('Agent'),
                keywordFilter2:this.createKeywordSelected('Customer'),
                complianceFilter:this.createComplianceSelected(),
                mode:this.selectKeywordRole!=='Both'?this.authService.userSessionData.searchMode:'Single',
                sfilter:this.sFilter.toString(),
                sortcol:this.sortColumn,
                sortdir:this.sortDir,
                startdate:this.selectedDate.startDate.format('YYYY-MM-DD'),
                startrow:'0',
                userid:this.authService.userSessionData.userid,
                filename:this.fileName,
                isNonCompliant:this.isNonCompliant,
                selectedCategory:this.selectedCategory
            }
        };
        if (this.commentField.length>0) options.params.comment=this.commentField;
        else if (this.comment) options.params.comment='';
        if (this.advancedQuery.sql.length>1) options.params.advancedQeury= this.advancedQuery.sql;           
        options.params.step='category';
        this.httpClient.post<{
            success: boolean;
            category: [];          
        }>(
            '/api/dashboardV2/getAccountStats', options)
            .subscribe((response: any)=>{ 
                this.categoryListLoaded=true;
                if (this.cacheguid!==guidAtStart) return;
                this.createCategoryList(response.category);
            });
    }

    getNewGuid(): void {
        if (this.cacheguid.length>0) this.retireGuid(this.cacheguid);
        this.cacheguid = getGuid();       
    }

    retireGuid(cacheGuid: string): void {
        this.httpClient.get('/api/dashboard/retireCache', {
            params: {
                cacheguid: cacheGuid
            }
        }).subscribe();
    }

   

    iTranslate(phrase: string): string{
        return phrase;
    }


    formatDate(dateAsString: string): string {
        const date = new Date(dateAsString);
        return date.toLocaleString();
    }
    
    formatTime(nbSeconds: number, source=''): string {
        if (source && source !== 'Audio' && source !== 'YouTube') {
            if (nbSeconds === undefined) return '';
            return nbSeconds.toFixed(0);
        }  
        const h = Math.floor(nbSeconds / 3600).toString().padStart(2,'0'),
            m = Math.floor(nbSeconds % 3600 / 60).toString().padStart(2,'0'),
            s = Math.floor(nbSeconds % 60).toString().padStart(2,'0');
            
        let response = h + ':' + m + ':' + s;
        if (response === 'NaN') {
            response = 'Unavailable';
        }
        return response;
    }

    displayPercent(number: number | undefined): string {
        if (!number) return '0';
        return number.toFixed(2);
    }

    createCategories(): void{
        const categories = JSON.parse(this.authService.userSessionData.categorizationJson);
        if (this.authService.userSessionData.assignmentAttr) {
            categories.push({
                category: this.authService.userSessionData.assignmentAttr,
                title: this.authService.userSessionData.assignmentAttr
            });
        }

        let x = 0;
        const colors = ['c80a9d', '2636a4', '842c88', '8a7dfd', '2abca5','A4243B','273E47',
            '9D9171','AB4E68','FF9F1C','080357','183642','393E41','400406','7A4419','515A47'];
        categories.forEach((category: {category: string;title: string})=> {
            this.currentCategoryValues[category.category] = {
                category: category.category,
                title: category.title,
                values: [],
                color: colors[x] ? colors[x] : Math.floor(Math.random() * 16777215).toString(16)
            };
            x++;
        });        
    }

    categoryList!: {values: {[key: string]: CategoryList []}};
    public categoryListUpdate: BehaviorSubject<CategoryList []> 
    = new BehaviorSubject([{ 
        audioCount: -1,
        audioDuration: -1,
        audioWithKeywordCount: -1,
        category: '',
        CategoryHeading: '',
        keywordCount: -1,
        sentiment: -1
    }]);
    
    createCategoryList(categories: CategoryList []): void{
        if (!categories) categories = [];
        this.categoryList = {'values':{}};
        categories.forEach((value) =>{
            if (this.categoryList.values[value.CategoryHeading] === undefined) {
                this.categoryList.values[value.CategoryHeading] = [];
            }
            this.categoryList.values[value.CategoryHeading].push(value);
        });
   
        if (this.categoryList.values.source !== undefined) {
            this.categoryList.values.source.sort(function(a, b) {
                if (a.audioDuration > b.audioDuration) {
                    return -1;
                }
                if (a.audioDuration < b.audioDuration) {
                    return 1;
                }
                return 0;
            });
        }
        
        this.categoryListUpdate.next(this.categoryList.values[this.selectedCategory]);
        // debugger;
    }

    keywordList!: KeywordList [];
    public keywordListUpdate: BehaviorSubject<KeywordList []> 
    = new BehaviorSubject([{ 
        calls: -1,
        calls2: -1,
        keyword: '',
        mentions: -1,
        mentions2: -1       
    }]);

    createKeywordList(keywords: KeywordList []): void{
        if (!keywords) keywords = [];
        this.keywordList = keywords;
        this.keywordListUpdate.next(this.keywordList);
    }

    complianceList!: ComplianceList [];
    public complianceListUpdate: BehaviorSubject<ComplianceList []> 
    = new BehaviorSubject([{ 
        complianceName: '',
        complianceOpportunities: -1,
        complianceResultAttr: '',
        complied: -1    
    }]);

    createComplianceList(compliances: ComplianceList []): void{
        if (!compliances) compliances = [];
        this.complianceList = compliances;
        this.complianceListUpdate.next(this.complianceList);
    }

    createCategorySelectedJSON(): string{
        const pushCats: {category: string;value: string} [] = [];
        Object.keys(this.currentCategoryValues).forEach(category=>{
            this.currentCategoryValues[category].values.forEach(value=>{
                pushCats.push({category: category, value: value});
            });
        });      
        return JSON.stringify(pushCats);
    }

    selectKeywordRole='';

    currentKeywordValues!: CurrentKeywordValues[];
    currentComplianceValues!: CurrentComplianceValues[];

    stereoConfig(): StereoConfig {       
        const label: string []=  [];
        label[this.authService.userSessionData.clientChannel] = this.authService.userSessionData.clientChannelLabel;
        label[this.authService.userSessionData.userChannel] = this.authService.userSessionData.userChannelLabel;
        return {
            isTwoChannel: this.authService.userSessionData.searchMode === 'dual' ? 1 : 0,
            channel1: this.authService.userSessionData.clientChannelLabel,
            channel2: this.authService.userSessionData.userChannelLabel,
            label: label
        };
    }

    createKeywordSelected(role: string): string{
        if (!(this.currentKeywordValues?.length>0)) return '';
        let keywordFilter ='';
        let andStr='';
        if (role ==='Agent' || role ==='' ) {           
            this.currentKeywordValues.forEach(value=>{
                if (value.stereo === role || value.stereo ===''  || this.selectKeywordRole ==='Both') {
                    keywordFilter = keywordFilter + andStr + value.operator +' ' + '"'+ value.keyword+ '"';
                    andStr = ' AND ';
                }
            });
            return keywordFilter;
        }             
        this.currentKeywordValues.forEach(value=>{
            if (value.stereo === role) {
                keywordFilter = keywordFilter + andStr + value.operator +' '+ '"'+value.keyword+'"';
                andStr = ' AND ';
            }
        }); 
        return keywordFilter;                      
    }

    createComplianceSelected(): string{
        if (!(this.currentComplianceValues?.length>0)) return '';
        let complianceFilter ='';
        let andStr='';
        this.currentComplianceValues.forEach(value=>{
            complianceFilter = complianceFilter + andStr + value.operator +' ' + '"'+ value.compliance+ '"';
            andStr = ' AND ';
        });
        return complianceFilter;
    }

    formatSource(source: string, location: number): string {
        if (source === 'Audio') {
            if (location === 1) {
                return 'duration';
            }
            if (location === 2) {
                return 'Call Contacts';
            }
        }
        if (source === 'Amazon') {
            if (location === 1) {
                return 'words';
            }
            if (location === 2) {
                return 'Amazon Contacts';
            }
        }
        if (source === 'Facebook') {
            if (location === 1) {
                return 'comments';
            }
            if (location === 2) {
                return 'Facebook Contacts';
            }
        }
        if (source === 'Instagram') {
            if (location === 1) {
                return 'comments';
            }
            if (location === 2) {
                return 'Instagram Contacts';
            }
        }
        if (source === 'Survey') {
            if (location === 1) {
                return 'words';
            }
            if (location === 2) {
                return 'Survey Contacts';
            }
        }
        if (source === 'YouTube') {
            if (location === 1) {
                return 'duration';
            }
            if (location === 2) {
                return 'YouTube Contacts';
            }
        }
        if (source === 'Twitter') {
            if (location === 1) {
                return 'Tweets';
            }
            if (location === 2) {
                return 'Twitter Contacts';
            }
        }
        return '';
    }

    formatMediaType(location: number): string {
        if (this.authService.isComplianceDash()) this.mediaTypeGroup = 'Call';
        if (this.mediaTypeGroup === 'Call') {
            if (location === 1) {
                return 'Calls';
            }
            if (location === 2) {
                return 'Call';
            }
            if (location === 3) {
                return 'Duration';
            }
        }
        if (this.mediaTypeGroup === 'Social') {
            if (location === 1) {
                return 'Posts';
            }
            if (location === 2) {
                return 'Post';
            }
            if (location === 3) {
                return 'Length';
            }
        }
        if (this.mediaTypeGroup === 'Reviews') {
            if (location === 1) {
                return 'Reviews';
            }
            if (location === 2) {
                return 'Review';
            }
            if (location === 3) {
                return 'Length';
            }
        }
        if (this.mediaTypeGroup === 'Surveys') {
            if (location === 1) {
                return 'Responses';
            }
            if (location === 2) {
                return 'Response';
            }
            if (location === 3) {
                return 'Length';
            }
        }
        if (this.mediaTypeGroup === 'Other') {
            if (location === 1) {
                return 'Contacts';
            }
            if (location === 2) {
                return 'Contact';
            }
            if (location === 3) {
                return 'Length';
            }
        }
        return '';
    }
    isComplianceDash(): boolean{ return this.authService.permission.ShowCompliance==='true';}

    processTransfer(transfer: TransferPayload): void{
        this.selectedDate={
            startDate:dayjs(transfer.reportStartDate),
            endDate:dayjs(transfer.reportEndDate)
        };
        this.selectedCategory = transfer.selectedCategory;
        this.sortColumn = transfer.sortColumn;
        this.sortDir = transfer.sortDir;
        this.currentCategoryValues = transfer.currentCategoryValues;
        this.currentKeywordValues = transfer.currentKeywordValue?.values;
        this.reloadData();
        if (transfer.selectedTranscript)
            this.matDialog.open(TranscriptWindowComponent, {
                height: '90%',
                width: '80%',
                data: {selectedTranscript:transfer.selectedTranscript as SelectedTranscript,
                    dashService:this},
                panelClass: 'transcript-container'
            });
    }

    downloadButtonText='Download Words';    
    downloadWords(): void{
        this.downloadButtonText='Preparing Download';  
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/csv');
      
        const options: any =  {
            headers: headers,
            responseType: 'text',
            params: {
                categorizationFilterJson:this.createCategorySelectedJSON(),
                enddate:this.selectedDate.endDate.format('YYYY-MM-DD'),
                keywordFilter:this.createKeywordSelected('Agent'),
                keywordFilter2: this.createKeywordSelected('Customer'),
                mode:this.selectKeywordRole!=='Both'?this.authService.userSessionData.searchMode:'Single',                
                sfilter:'0',
                sortcol:this.sortColumn,
                sortdir:this.sortDir,
                startdate:this.selectedDate.startDate.format('YYYY-MM-DD'),
                userid:this.authService.userSessionData.userid,
                filename:this.fileName
            }
        };

        if (this.commentField.length>0) options.comment=this.commentField;
        else if (this.comment) options.comment='';

        this.httpClient.get('/api/audio/wordCountEmail',options)
            .subscribe((response)=>{
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.href = 'data:attachment/csv;charset=utf-8,' + encodeURI(response.toString());
                a.target = '_blank';
                a.download = 'Word_count.csv';
                a.click();
                this.downloadButtonText='Download Words';  
            });
    } 

    isNonCompliantToggle(): void{
        this.isNonCompliant = this.isNonCompliant===1?0:1;
        this.reloadData();
    }
}

