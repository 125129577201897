<div class="login ng-scope">
    <div class="content" ng-class="">
        <form [formGroup]="loginFormGroup" (ngSubmit)="login()" *ngIf="loginForm">
            <img src="../assets/images/beta.jpeg" *ngIf="displayBeta()">
            <h3 class="form-title ng-binding">Sign In</h3>
            <div class="form-group">
                <mat-form-field appearance="fill">
                    <mat-label>User Name</mat-label>
                    <input matInput formControlName="userName" name="username">
                    <mat-error *ngIf="loginFormGroup?.controls?.userName?.dirty && loginHasError('userName', 'required')">Name
                        is required</mat-error>
                    <mat-error *ngIf="loginFormGroup?.controls?.userName?.dirty && loginHasError('userName', 'maxlength')">
                        Length is too long</mat-error>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field appearance="fill" class="">
                    <mat-label>Password</mat-label>
                    <input matInput formControlName="password" type="password" autocomplete="off" placeholder="Password" name="password">
                    <mat-error *ngIf="loginFormGroup?.controls?.password?.dirty && loginHasError('password', 'required')">
                        Password is required</mat-error>
                    <mat-error *ngIf="loginFormGroup?.controls?.password?.touched && loginHasError('password', 'maxlength')">
                        Length is too long</mat-error>
                </mat-form-field>
            </div>
            <div class=" form-actions">
                <label class="rememberme check ng-binding">
                    <input matCheckbox type="checkbox" name="remember" value="1" ng-class="{moveRt: applyrtlClass}">Remember
                </label>
                <a href="/login#forgotPassword" id="forget-password" class="forget-password" ng-class="{stayLt: applyrtlClass}">
                    <span class="forgot-password">Forgot
                        Password?</span></a><br>
                <button mat-button type="submit" class="btn btn-success uppercase">Login</button>
            </div>
            <div class=" hide create-account">
                <p class="">
                    <a href="/login#showRegistrationForm" id="register-btn" class="uppercase ng-binding">Create
                        Account</a>
                </p>
            </div>

        </form>
    </div>
</div>