import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {HttpClient} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialogRef} from '@angular/material/dialog';
@Component({
    selector: 'app-add-script-window',
    templateUrl: './add-script-window.component.html',
    styleUrls: ['./add-script-window.component.css']
})
export class AddScriptWindowComponent implements OnInit {
    scriptName='';
    scriptText='';

    constructor(
        private authService: AuthService,
        private httpClient: HttpClient,
        private snackbar: MatSnackBar,
        private dialogRef: MatDialogRef<AddScriptWindowComponent>
    ) { }

    ngOnInit(): void {
    }

    saveScript(): void {
        if (!this.scriptName.length || !this.scriptText.length){
            this.snackbar.open('Error - Name and Text required','Dismiss', {               
                verticalPosition: 'top', 
                horizontalPosition: 'right',
                duration: 2000,
                panelClass:'error'
            });  
            return;
        }
        this.httpClient.post('/api/adminCompliance/addScript',{
            accountId:this.authService.userSessionData.accountid,
            scriptName:this.scriptName,
            scriptText:this.scriptText
        }).subscribe(response =>{
            if (response['success']) {
                this.snackbar.open('Success Adding Script','Dismiss', {               
                    verticalPosition: 'top', 
                    horizontalPosition: 'right',
                    duration: 2000,
                    panelClass:'success'
                });                
                this.dialogRef.close(true);     
            } else{
                this.snackbar.open('Error Adding Script','Dismiss', {               
                    verticalPosition: 'top', 
                    horizontalPosition: 'right',
                    duration: 2000,
                    panelClass:'error'
                });  
            }
        });
    }

}
