<div class="container" [loadSpinner]="!dashService.categoryListLoaded">
    <div fxLayout="row" style="overflow:auto;" class="example-header flx">
        <h2 fxFlex="auto" class="text-lucid">Categories</h2>
        <div fxFlex="4%">
        </div>
        <mat-form-field appearance="fill" fxFlex="auto">
            <mat-label>Search</mat-label>
            <input matInput (keyup)="applyFilter($event)">
        </mat-form-field>
        <div fxFlex="5%">
        </div>
        <div fxFlex="30%" style="align-items:right">
            <mat-form-field appearance="fill">
                <mat-select (selectionChange)="selectionChange()" [(value)]="dashService.selectedCategory">
                    <mat-option *ngFor="let category of dashService.currentCategoryValues | keyvalue" [value]="category.value.category">
                        {{category.value.title}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex="5%"></div>
        <div fxFlex="auto" style="margin-left: 5px;">
            <button style="float:right" matTooltip="Export Data" color="primary" matTooltipPosition="above" mat-mini
                (click)="exporter.exportTable('csv',{fileName:'categories'})">
                <mat-icon>file_download</mat-icon>
            </button>
        </div>
    </div>
    <div fxLayout="row" style="overflow:none;" class="example-header flx">
        <div fxFlex="2%">
        </div>
        <div fxFlex="30%">
            <mat-checkbox (change)="dashService.reloadFilter()" [(ngModel)]="dashService.sFilterBool">Filter Results</mat-checkbox>
        </div>
        <div fxFlex="60%">
        </div>
        <div fxFlex="3%">

        </div>
    </div>
    <div fxLayout="row" style="overflow:auto;height:321px">
        <table matTableExporter [hiddenColumns]="[5]" #exporter="matTableExporter" mat-table matSort (matSortChange)="sortData()" [dataSource]="dataSource"
            class="mat-elevation-z8 category-table">
            <ng-container matColumnDef="category">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{categoryHeading}} </th>
                <td mat-cell *matCellDef="let element"> {{element.category}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="audioCount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Calls </th>
                <td mat-cell *matCellDef="let element"> {{element.audioCount}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="audioWithKeywordCount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Mentions </th>
                <td mat-cell *matCellDef="let element"> {{element.audioWithKeywordCount}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="audioDuration">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Duration </th>
                <td mat-cell *matCellDef="let element"> {{dashService.formatTime(element.audioDuration)}} </td>
            </ng-container>

            <ng-container matColumnDef="sentiment">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Experience </th>
                <td mat-cell *matCellDef="let element"> {{(element.sentiment).toFixed(1)}} </td>
            </ng-container>
            <ng-container matColumnDef="complianceOpportunities">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Compliance Opportunities </th>
                <td mat-cell *matCellDef="let element"> {{element.complianceOpportunities}} </td>
            </ng-container>
            <ng-container matColumnDef="complied">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Complied </th>
                <td mat-cell *matCellDef="let element"> {{element.complied}} </td>
            </ng-container>
            <ng-container matColumnDef="compliancePercent">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Compliance Percent</th>
                <td mat-cell *matCellDef="let element"> {{(element.compliancePercent*100).toFixed(2)}} </td>
            </ng-container>
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Select </th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon (click)="selectCategory(element)" style="cursor: pointer;color: #3a8fc8;">add_circle</mat-icon>
                    <mat-icon (click)="selectTrends(categoryHeading,element.category)" style="cursor: pointer;color: #3a8fc8;">analytics</mat-icon>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 20,1000]" showFirstLastButtons></mat-paginator>
</div>