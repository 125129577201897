import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AuthService} from '../../services/auth.service';
import {HttpClient} from '@angular/common/http';
import {DashService} from '../../services/dash.service';
import {TranscriptService, TranscriptResponse, Word} from '../../services/transcript.service';
import * as WaveSurfer from 'wavesurfer.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline';
import { faFrown,faSmile,faMeh } from '@fortawesome/free-regular-svg-icons';
import * as Highcharts from 'highcharts';
import { NgxSpinnerService } from 'ngx-spinner';

interface TrendRow  {
    dt: string;
    cnt: number;
    pct: number;
}
@Component({
    selector: 'app-trend-window',
    templateUrl: './trend-window.component.html',
    styleUrls: ['./trend-window.component.css']
})

export class TrendWindowComponent implements OnInit {

    highcharts: typeof Highcharts = Highcharts;
    updateFlag = false;
    dataLoaded = false;
    chartOptions: Highcharts.Options = {
        series: [{
            data: [1],
            type: 'line'
        }]
    };

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {chartAttr: string;chartValue: string},
        public authService: AuthService,
        public dashService: DashService,
        public httpClient: HttpClient,   
        public transcriptService: TranscriptService,
        private spinner: NgxSpinnerService
    ) { }

  
    prepTrendData(chartValue: string, data: TrendRow[]): any {
        const finalData: any = [];
        let interData = {};
        const dates: string[] = [];   
        const keys = Object.keys(data[0]);
        keys.forEach((key) => {
            interData[key] = [];
        });
        data.forEach((obj) => {
            keys.forEach((key) => {
                if (key === 'dt') {
                    dates.push(obj.dt.substring(0, 10));
                } else {
                    interData[key].push(parseFloat(obj[key].toFixed(2)));
                }
            });
        });
        keys.forEach((key, i) => {
            if (key !== 'dt') {
                finalData.push({
                    name: chartValue + ': ' + key,
                    data: interData[key],
                    yAxis: i-1,
                    tooltip: {
                        valueSuffix: key === 'pct' ? ' %' : ''
                    }
                });
            }
        });
        interData = {};
        return {x: dates, y: finalData};
    }

    ngOnInit(): void {
        this.spinner.show(); 
        this.httpClient.post(
            '/api/dashboard/getCategoryTrend', {
                cacheguid:this.dashService.cacheguid,
                categorizationFilterJson:this.dashService.createCategorySelectedJSON(),
                enddate:this.dashService.selectedDate.endDate.format('YYYY-MM-DD'),                   
                keywordFilter:this.dashService.createKeywordSelected('Agent'),
                keywordFilter2:this.dashService.complianceFreeSearch || this.dashService.createKeywordSelected('Customer'),
                mode:'dual',
                sfilter:'0',
                sortcol:this.dashService.sortColumn,
                sortdir:this.dashService.sortDir,
                startdate:this.dashService.selectedDate.startDate.format('YYYY-MM-DD'),
                userid:this.authService.userSessionData.userid,
                chartVal: this.data.chartValue,
                chartAttr: this.data.chartAttr
            })
            .subscribe((response)=>{
                this.spinner.hide();
                const data = this.prepTrendData(this.data.chartValue,response[0] as TrendRow[]);
                this.chartOptions = {
                    exporting: {
                        buttons: {
                            contextButton: {
                                menuItems: ['printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'separator', 'downloadCSV']
                            }
                        },
                        fallbackToExportServer: false
                    },
                    chart: {
                        renderTo: 'trendChart',
                        // type: 'line'
                        zoomType: 'xy'
                    },
                    title: {
                        text: ''
                    },
                    xAxis: {
                        categories: data.x,
                        crosshair: true
                    },
                    yAxis: [{ // Primary yAxis
                        labels: {
                            format: '{value}'
                        },
                        title: {
                            text: 'Count'
                        }
                    },
                    { // Secondary yAxis
                        gridLineWidth: 0,
                        title: {
                            text: 'Percent'
                        },
                        labels: {
                            format: '{value} %'
                        },
                        opposite: true
                    }],
                    tooltip: {
                        shared: true
                    },
                    plotOptions: {
                        series: {
                            marker: {
                                enabled: false
                            }
                        }
                    },
                    series: data.y
                };
                this.updateFlag = true;
                this.dataLoaded = true;
            });
    }
  
}
